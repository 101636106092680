import React, { useCallback, useEffect } from 'react'
import { useSplit } from '@buffer-mono/features'

import {
  cleanupChannelConnectionCookies,
  getReconnectingChannelCookie,
} from '../../../pages/Channels/utils/cookies'
import ChannelConnectionsContainer from '../ChannelsConnectionsContainer/ChannelsConnectionsContainer'
import { getIsAuthenticationRedirect, handleSuccessRedirect } from '../utils'

import {
  useChannelConnections,
  useChannelConnectionsDispatch,
} from '../../../context/ChannelConnections/ChannelConnections'
import { ChannelConnectionsActions } from '../ChannelConnectionsProvider/channelConnectionsReducer'
import { OAuthFlowErrorType } from '../../../pages/Channels/OAuthFlow/types'
import useAuthorizeAndConnectSingleChannel from '../../../hooks/channel-connections/useAuthorizeAndConnectSingleChannel/useAuthorizeAndConnectSingleChannel'

export default function PostAuthenticationRedirect(): JSX.Element | null {
  const stateDispatch = useChannelConnectionsDispatch()
  const {
    selectedService,
    connectionCompleted,
    connectedChannelIds,
    isNewConnection,
  } = useChannelConnections()

  const { split } = useSplit('geid-posting-goals-channel-setup')

  const { isAuthenticationRedirect, serviceNameFromRedirect } =
    getIsAuthenticationRedirect()

  function handleOnRefreshSuccess(
    connectedChannelIds: string[],
    isNewConnection: boolean,
  ): void {
    stateDispatch({
      type: ChannelConnectionsActions.CONNECTION_SUCCESS,
      channelIds: connectedChannelIds,
      isNewConnection,
    })
  }
  const {
    handleAuthorizeAndConnectSingleChannel,
    isAuthorizeAndConnectSingleChannelLoading,
    connectedChannels,
  } = useAuthorizeAndConnectSingleChannel({
    onSuccess: handleOnRefreshSuccess,
    service: selectedService || undefined,
  })

  const reconnectingChannelCookie = getReconnectingChannelCookie()
  const hasReconnectingCookie = Boolean(reconnectingChannelCookie)

  const isValidRedirect = isAuthenticationRedirect && serviceNameFromRedirect
  const isReconnectingChannels = isValidRedirect && hasReconnectingCookie

  const shouldStartChannelConnection =
    isValidRedirect && !isReconnectingChannels && selectedService

  const handleOnChannelConnectionSuccess = useCallback(
    (connectedChannelIds: string[], isNewConnection: boolean): void => {
      if (connectedChannelIds.length > 0 && selectedService) {
        stateDispatch({
          type: ChannelConnectionsActions.RESET_CHANNEL_CONNECTION_STATE,
        })
        handleSuccessRedirect(
          connectedChannelIds,
          isNewConnection,
          Boolean(isReconnectingChannels),
          split.treatment as `variant_${string}` | 'ineligible',
        )

        cleanupChannelConnectionCookies(selectedService)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedService, isReconnectingChannels, split.treatment],
  )

  const handleErrors = useCallback(
    (errorType: OAuthFlowErrorType): void => {
      if (errorType && selectedService) {
        stateDispatch({
          type: ChannelConnectionsActions.CONNECTION_FAILED,
          errorType,
        })

        cleanupChannelConnectionCookies(selectedService)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedService],
  )

  useEffect(() => {
    // We only want to set the selected service once on first mount
    if (serviceNameFromRedirect) {
      stateDispatch({
        type: ChannelConnectionsActions.SET_SELECTED_SERVICE,
        selectedService: serviceNameFromRedirect,
        isRefreshingConnection: isReconnectingChannels || false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (connectionCompleted && connectedChannelIds) {
      handleOnChannelConnectionSuccess(connectedChannelIds, isNewConnection)
    }
  }, [
    connectionCompleted,
    connectedChannelIds,
    handleOnChannelConnectionSuccess,
    isNewConnection,
  ])

  // Trigger Channel Refresh
  useEffect(() => {
    if (isReconnectingChannels && selectedService) {
      stateDispatch({
        type: ChannelConnectionsActions.SET_CONNECTION_LOADING,
      })

      handleAuthorizeAndConnectSingleChannel({
        service: selectedService,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReconnectingChannels, selectedService])

  useEffect(() => {
    if (!isValidRedirect) {
      handleErrors(OAuthFlowErrorType.unexpectedError)
    }
  }, [isValidRedirect, handleErrors])

  // Handle Refreshing Error
  useEffect(() => {
    const didNotReturnAnyChannelsOnRefreshAttempt = Boolean(
      !isAuthorizeAndConnectSingleChannelLoading &&
        connectedChannels?.length === 0,
    )

    if (didNotReturnAnyChannelsOnRefreshAttempt) {
      handleErrors(OAuthFlowErrorType.failedToGetCredentials)
    }
  }, [connectedChannels])

  if (shouldStartChannelConnection) {
    return <ChannelConnectionsContainer selectedService={selectedService} />
  }

  return null
}
