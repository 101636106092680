import { ChannelThirdPartyService } from '../../../../../../channel-connections/interfaces'

import { getBufferPageSourceURLCookie } from '../../utils/cookies'

function checkUserCanceledChannelConversion(): void {
  const queryParams = new URLSearchParams(window.location.search)
  const errorDescription = queryParams.get('error_description')
  const errorReason = queryParams.get('error_reason')

  const possibleErrorDescriptions = [
    'Permissions error',
    'The user denied your request.',
  ]
  const possibleReasons = ['user_denied']

  const hasValidErrorDescription =
    errorDescription && possibleErrorDescriptions.includes(errorDescription)
  const hasValidErrorReason =
    errorReason && possibleReasons.includes(errorReason)

  const lastBufferPageUrl = getBufferPageSourceURLCookie()

  if (hasValidErrorDescription || hasValidErrorReason) {
    if (lastBufferPageUrl) {
      return window.location.assign(lastBufferPageUrl)
    }

    // Fallback to redirect user to channels list page
    return window.location.assign('/channels')
  }
}

export function handleRedirects({
  service,
}: {
  service: ChannelThirdPartyService
}): void {
  const isInstagram =
    service === ChannelThirdPartyService.instagram ||
    service === ChannelThirdPartyService.instagram_login_api

  const isFacebook = service === ChannelThirdPartyService.facebook

  if (!isInstagram && !isFacebook) {
    return
  }

  checkUserCanceledChannelConversion()
}
