import React, { useState, useEffect } from 'react'
import {
  ArrowDownUpIcon,
  Avatar,
  BufferIcon,
  Button,
  Card,
  ChannelIcon,
  Checkbox,
  CheckboxCard,
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  DropdownMenu,
  Flex,
  Heading,
  InfoIcon,
  Text,
  Tooltip,
} from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useAccount } from '../../../context/Account'
import type { ConnectableChannelData } from '../../../hooks/channel-connections/types'
import { trackChannelConnectionAborted } from '../tracking'
import type { ChannelThirdPartyService } from '~/legacy/channel-connections/interfaces'

import { getHelpLinks } from './helpItems'
import styles from './ConfirmChannelSelection.module.css'
import capitalize from 'lodash/capitalize'
import { SwitchAccount } from './SwitchAccount'

const statusMap = {
  alreadyConnected: {
    icon: CheckIcon,
    text: 'Connected',
    tooltip: 'This account is already connected to your Buffer organization.',
  },
  unavailable: {
    icon: InfoIcon,
    text: 'Unavailable',
    tooltip:
      'This account is already connected to another Buffer organization.',
  },
  available: null,
}

function Status({
  connectionAvailability,
}: {
  connectionAvailability?: keyof typeof statusMap
}): JSX.Element | null {
  if (!connectionAvailability) {
    return null
  }

  const mappedStatus = statusMap[connectionAvailability]

  if (mappedStatus) {
    return (
      <Tooltip content={mappedStatus.tooltip}>
        <div className={styles.status}>
          <mappedStatus.icon />
          <Text>
            <strong>{mappedStatus.text}</strong>
          </Text>
        </div>
      </Tooltip>
    )
  }

  return null
}

function getSelectableChannels(
  availableChannels: ConnectableChannelData[],
): ConnectableChannelData[] {
  return availableChannels.filter(
    (channel) => channel.connectionAvailability === 'available',
  )
}

export function ConfirmChannelSelection({
  availableChannels,
  handleSubmit,
  selectedService,
}: {
  availableChannels: ConnectableChannelData[]
  handleSubmit: (channel: ConnectableChannelData[]) => void
  selectedService: ChannelThirdPartyService
}): JSX.Element {
  const selectableChannels = getSelectableChannels(availableChannels)
  const [selectedChannels, setSelectedChannels] = useState<
    ConnectableChannelData[]
  >(selectableChannels.length === 1 ? selectableChannels : [])
  const [isScrolled, setIsScrolled] = useState(false)
  const [switchAccount, setSwitchAccount] = useState(false)

  const account = useAccount()

  useEffect(() => {
    BufferTracker.channelConnectionProfileSelectorViewed({
      organizationId: account?.currentOrganization?.id || '',
      product: 'account',
      channel: 'instagram',
      clientName: 'core',
    })
  }, [account?.currentOrganization?.id])

  if (switchAccount) {
    return (
      <SwitchAccount
        onBack={(): void => setSwitchAccount(false)}
        selectedService={selectedService}
        account={account}
      />
    )
  }

  return (
    <Card padding="none" className={styles.base}>
      <div className={styles.header}>
        <div className={styles.switchAccount}>
          <Button
            variant="tertiary"
            onClick={(): void => {
              setSwitchAccount(true)
            }}
          >
            Switch Account
          </Button>
        </div>
        <div className={styles.headerChannels}>
          <Flex gap="sm">
            <BufferIcon />
            <ArrowDownUpIcon className={styles.channelsArrows} />
            <ChannelIcon type={selectedService} color="branded" />
          </Flex>
        </div>
        <Button
          className={styles.closeButton}
          variant="tertiary"
          size="small"
          aria-label="Close"
          onClick={(): void => {
            trackChannelConnectionAborted({
              account,
              serviceName: selectedService,
            })
            window.location.assign('/channels/connect')
          }}
        >
          <CloseIcon />
        </Button>
      </div>
      <div className={styles.body}>
        <Flex direction="column" align="center" fullWidth fullHeight>
          <Heading size="large" className={styles.heading}>
            {availableChannels.length === 1
              ? 'Confirm your Account'
              : 'Select accounts'}
          </Heading>
          <div
            className={
              isScrolled
                ? styles.checkboxGroupContainerScrolled
                : styles.checkboxGroupContainer
            }
          >
            {selectableChannels.length > 1 && (
              <div className={styles.checkboxGroupHeader}>
                <Text>{selectedChannels.length} selected</Text>

                <Checkbox
                  disabled={!selectableChannels.length}
                  checked={
                    selectedChannels.length === selectableChannels.length
                  }
                  onChange={(checked): void => {
                    if (checked) {
                      setSelectedChannels(selectableChannels)
                    } else {
                      setSelectedChannels([])
                    }
                  }}
                >
                  Select All
                </Checkbox>
              </div>
            )}
          </div>

          <CheckboxCard.Group
            className={styles.checkboxGroup}
            onScroll={(event): void => {
              setIsScrolled(event.currentTarget.scrollTop > 0)
            }}
          >
            {availableChannels.map((channel) => {
              return (
                <CheckboxCard
                  key={channel?.serviceId}
                  disabled={channel.connectionAvailability !== 'available'}
                  checked={selectedChannels.includes(channel)}
                  className={styles.checkbox}
                  data-testid={`checkbox-${channel?.serviceId}`}
                  onCheckedChange={(checked): void => {
                    if (checked) {
                      setSelectedChannels((prev) => [...prev, channel])
                    } else {
                      setSelectedChannels((prev) =>
                        prev.filter((prevChannel) => prevChannel !== channel),
                      )
                    }
                  }}
                >
                  <div className={styles.detailsGroup}>
                    <Avatar
                      src={channel.avatar}
                      alt={channel.name}
                      size="medium"
                    />
                    <div className={styles.channelDetails}>
                      <Text className={styles.channelName}>
                        <strong>{channel.name}</strong>
                      </Text>
                      {channel.type && (
                        <Text color="subtle">{capitalize(channel.type)}</Text>
                      )}
                    </div>
                  </div>

                  <Status
                    connectionAvailability={channel.connectionAvailability}
                  />
                </CheckboxCard>
              )
            })}
          </CheckboxCard.Group>
        </Flex>
      </div>

      <div className={styles.footer}>
        <DropdownMenu className={styles.helpMenu}>
          <DropdownMenu.Trigger>
            <Button
              variant="tertiary"
              size="large"
              className={styles.helpButton}
            >
              Need Help
              <ChevronDownIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="start">
            {getHelpLinks(selectedService).map(
              ({ id, link, onSelect, title }) => (
                <DropdownMenu.Item key={id} onSelect={onSelect}>
                  {link ? (
                    <a
                      className={styles.helpLink}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {title}
                    </a>
                  ) : (
                    title
                  )}
                </DropdownMenu.Item>
              ),
            )}
          </DropdownMenu.Content>
        </DropdownMenu>
        <Button
          size="large"
          disabled={!selectedChannels.length}
          onClick={(): void => {
            handleSubmit(selectedChannels)
          }}
          data-testid="finish-connection-button"
        >
          Finish Connection
        </Button>
      </div>
    </Card>
  )
}
