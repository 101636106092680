/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// @ts-nocheck
import { env } from '../env'

if (env.VITE_NODE_ENV === 'production') {
  ;(function (h, o, u, n, d): void {
    h = h[d] = h[d] || {
      q: [],
      onReady: function (c) {
        h.q.push(c)
      },
    }
    d = o.createElement(u)
    d.async = 1
    d.src = n
    n = o.getElementsByTagName(u)[0]
    n.parentNode.insertBefore(d, n)
  })(
    window,
    document,
    'script',
    'https://www.datadoghq-browser-agent.com/datadog-rum.js',
    'DD_RUM',
  )

  DD_RUM.onReady(function () {
    DD_RUM.init({
      clientToken: 'pub5ce2b4bb2430bdac18862d2739ddaab4',
      applicationId: '79091d41-d58a-4739-a552-1c732738bffc',
      site: 'datadoghq.com',
      service: 'account',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 1,
      trackInteractions: true,
    })
  })
}
