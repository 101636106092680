import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useSplitEnabled } from '@buffer-mono/features'

import {
  generic,
  billingPage,
  channelsPage,
  organizationPage,
  betaProgramPage,
  channelsConnectPage,
  onboardingPage,
  accountDeletedConfirmationPage,
  newPlansPage,
  billingStripePortalPage,
  referralsPage,
  alphaProgramPage,
  oAuthConnectionPage,
} from '../../../../routes'

import AccountDeleted from '../AccountDeleted'
import BillingPage from '../Billing'
import BillingStripePortalPage from '../BillingStripePortal'
import NewPlansPage from '../NewPlans'
import ChannelsPage from '../Channels/ChannelsPage'
import ConnectChannelsPage from '../Channels/ConnectChannelsPage'
import LoadingPage from '../Loading'
import OrganizationsPage from '../Organizations'
import ReferralsPage from '../Referrals'
import BetaFeaturesPage from '../BetaFeatures'

import { AccountContext } from '../../context/Account'
import { ChannelConnectionContextProvider } from '../../../../channel-connections/context/ChannelConnection'

import { HelpScoutBeacon } from '../../../../helpScoutBeacon'

import { AppWrapper } from './style'
import { ProductOnboarding } from '../../../../onboarding'

import { GET_ACCOUNT } from '../../apollo/queries/account'
import { GET_CHANNELS_FOR_ORGANIZATION } from '../../apollo/queries/channels'

import { getRedirect, getUsername } from '../../selectors'
import { actionTypes } from '../../reducer'
import { actions as centralizedBillingActions } from '../../../../centralized-billing/reducer'
import AlphaFeaturesPage from '../AlphaFeatures/AlphaFeaturesPage'

import OAuthFlow from '../Channels/OAuthFlow/OAuthConnection'
import ChannelConnectionsProvider from '../../components/ChannelConnections/ChannelConnectionsProvider/ChannelConnectionsProvider'
import { getProductUrl } from '~/legacy/shared-utils'

const newSettingsUrl = `${getProductUrl('publish')}/settings`
const ExternalRedirect = ({ to }: { to: string }): null => {
  const history = useHistory()
  React.useEffect(() => {
    window.location.href = to
  }, [to, history])
  return null
}

function getProductFromQuery(query: string): string {
  const defaultProduct = 'publish'
  if (!query) return defaultProduct
  if (query.includes('analyze')) {
    return 'analyze'
  }
  return defaultProduct
}
interface Detail {
  action: string
}

interface AppProps {
  openCreditCardFormModal: () => void
}

export interface AppLocationState {
  shouldShowChannelStorefront?: boolean
}

const App = ({ openCreditCardFormModal }: AppProps): JSX.Element => {
  const { isEnabled: isAlphaProgramEnabled } = useSplitEnabled(
    'alpha-program-customers',
  )

  const dispatch = useDispatch()

  const {
    loading,
    error,
    data,
    refetch: refetchAccount,
  } = useQuery(GET_ACCOUNT)

  const [getChannelList] = useLazyQuery(GET_CHANNELS_FOR_ORGANIZATION)

  function handleOrgSwitch(detail: Detail): void {
    const { billingUpdated } = window?.appshell?.actionKeys || {}
    if (!detail?.action) {
      refetchAccount()
    } else if (detail?.action === billingUpdated) {
      refetchAccount()
      getChannelList()
    }
  }

  useEffect(() => {
    const { ORGANIZATION_EVENT_KEY } = window?.appshell?.eventKeys || {}

    window.addEventListener(ORGANIZATION_EVENT_KEY, handleOrgSwitch)

    return function cleanup() {
      window.removeEventListener(ORGANIZATION_EVENT_KEY, handleOrgSwitch)
    }
  }, [window?.appshell])

  useEffect(() => {
    if (loading) {
      dispatch({ type: actionTypes.GET_ACCOUNT_REQUEST })
    }
    if (error) {
      dispatch({ type: actionTypes.GET_ACCOUNT_FAILURE })
    }
    if (data && data.account) {
      const { account } = data

      dispatch({
        type: actionTypes.GET_ACCOUNT_SUCCESS,
        account,
      })
      dispatch(
        centralizedBillingActions.selectOrganization(
          account.currentOrganization.billingDetails.allStripeAccounts[0],
        ),
      )
    }
  }, [data, loading, error])

  const account = useSelector((state) => state.account)
  const billing = useSelector((state) => state.centralizedBilling)
  const state = useSelector((state) => state)
  const isHelpScoutBeaconEnabled = !!window.Beacon

  const { pathname } = state.router.location

  const { isEnabled: isRedirectToStorefront } = useSplitEnabled(
    'growth-redirect-channel-storefront',
    {
      email: account?.email,
    },
  )

  useEffect(() => {
    HelpScoutBeacon.updateHelpScoutBeacon(pathname)
  }, [pathname, isHelpScoutBeaconEnabled])

  useEffect(() => {
    // Re-fetch account details when a user has updated their credit card
    // or plan
    refetchAccount()
  }, [billing.changePlanSuccess])

  const {
    accountLoaded,
    currentOrganization,
    email,
    enabledProducts,
    featureFlips,
    isImpersonation,
    hasVerifiedEmail,
  } = account

  const redirect = getRedirect(state)
  const product = getProductFromQuery(redirect)
  const username = getUsername(state)

  const allowMigrationHubAccess =
    !currentOrganization?.isOneBufferOrganization || false

  const pageProps = {
    accountLoaded,
    billing,
    currentOrganization,
    email,
    hasVerifiedEmail,
    enabledProducts,
    featureFlips,
    isImpersonation,
    loading,
    openCreditCardFormModal,
    pathname,
    product,
    redirect,
    username,
    oneBufferBilling: data?.account || null,
  }

  const isAccountLoading = loading || data?.account === undefined

  return (
    <AppWrapper>
      {isAccountLoading ? (
        <LoadingPage />
      ) : (
        <AccountContext.Provider value={data?.account}>
          <ChannelConnectionsProvider>
            <ChannelConnectionContextProvider>
              <Switch>
                <Route
                  path={oAuthConnectionPage.route}
                  exact
                  render={(): JSX.Element | undefined => {
                    return <OAuthFlow />
                  }}
                />
                <Route
                  path={onboardingPage.route}
                  component={ProductOnboarding}
                />

                <Route
                  path={billingPage.route}
                  exact
                  render={(): JSX.Element => (
                    <BillingPage {...pageProps} refetch={refetchAccount} />
                  )}
                />
                <Route
                  path={billingStripePortalPage.route}
                  exact
                  render={(): JSX.Element => (
                    <BillingStripePortalPage
                      {...pageProps}
                      refetch={refetchAccount}
                    />
                  )}
                />
                <Route
                  path={newPlansPage.route}
                  exact
                  render={(): JSX.Element =>
                    allowMigrationHubAccess ? (
                      <NewPlansPage {...pageProps} refetch={refetchAccount} />
                    ) : (
                      <ExternalRedirect to={newSettingsUrl} />
                    )
                  }
                />
                <Route path={channelsConnectPage.route}>
                  {isRedirectToStorefront ? (
                    <Redirect
                      to={{
                        pathname: channelsPage.route,
                        state: { shouldShowChannelStorefront: true },
                      }}
                    />
                  ) : (
                    <ConnectChannelsPage />
                  )}
                </Route>
                <Route path={channelsPage.route}>
                  <ChannelsPage refetch={refetchAccount} />
                </Route>
                <Route
                  path={organizationPage.route}
                  exact
                  render={(): JSX.Element => (
                    <OrganizationsPage refetch={refetchAccount} />
                  )}
                />
                <Route
                  path={betaProgramPage.route}
                  exact
                  render={(): JSX.Element => (
                    <BetaFeaturesPage refetch={refetchAccount} />
                  )}
                />
                <Route
                  path={alphaProgramPage.route}
                  exact
                  render={(): JSX.Element =>
                    isAlphaProgramEnabled ? (
                      <AlphaFeaturesPage refetch={refetchAccount} />
                    ) : (
                      <ExternalRedirect to={newSettingsUrl} />
                    )
                  }
                />
                <Route
                  path={accountDeletedConfirmationPage.route}
                  exact
                  render={(): JSX.Element => <AccountDeleted />}
                />
                <Route
                  path={referralsPage.route}
                  exact
                  render={(): JSX.Element => (
                    <ReferralsPage refetch={refetchAccount} />
                  )}
                />
                <Route
                  path={generic.route}
                  exact
                  render={(): JSX.Element => (
                    <ExternalRedirect to={newSettingsUrl} />
                  )}
                />
                <Route path="*">
                  <Redirect to={generic.route} />
                </Route>
              </Switch>
            </ChannelConnectionContextProvider>
          </ChannelConnectionsProvider>
        </AccountContext.Provider>
      )}
    </AppWrapper>
  )
}

App.propTypes = {
  openCreditCardFormModal: PropTypes.func,
}

export default App
