import React, { useEffect } from 'react'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import {
  Button,
  Card,
  ChannelAvatar,
  CloseIcon,
  Flex,
  Heading,
  Text,
} from '@buffer-mono/popcorn'

import { useAccount } from '~/legacy/web/src/context/Account'
import { trackChannelConnectionAborted } from '~/legacy/web/src/components/ChannelConnections/tracking'
import styles from './SingleChannelAlreadyConnected.module.css'
import type { ChannelThirdPartyService } from '~/legacy/channel-connections/interfaces'
import { getServiceName } from '../../../pages/Channels/utils/channels'

export function SingleChannelAlreadyConnected({
  avatar,
  channelName,
  service, // lowercased service name
}: {
  avatar: string | undefined
  channelName: string | undefined
  service: ChannelThirdPartyService
}): JSX.Element {
  const account = useAccount()

  const serviceName = getServiceName(service) // human readable service name

  useEffect(() => {
    BufferTracker.dialogViewed({
      dialogName: 'SingleChannelAlreadyConnected',
      clientName: 'web',
      organizationId: account?.currentOrganization?.id,
      product: 'account',
    })
  }, [account, serviceName])

  const backToChannels = (): void => {
    trackChannelConnectionAborted({
      account,
      serviceName: service,
    })
    window.location.assign('/channels/connect')
  }

  return (
    <Card className={styles.base} padding="none">
      <Flex
        align="center"
        direction="column"
        justify="between"
        className={styles.wrapper}
      >
        <Flex
          justify="between"
          align="center"
          fullWidth
          className={styles.header}
        >
          <div className={styles.headerSpacing}></div>
          <Button
            className={styles.closeButton}
            variant="tertiary"
            size="small"
            onClick={backToChannels}
          >
            <CloseIcon />
          </Button>
        </Flex>
        <Flex
          direction="column"
          align="center"
          justify="center"
          gap="sm"
          className={styles.body}
        >
          <Flex direction="column" gap="lg" align="center">
            <ChannelAvatar
              src={avatar}
              channel={service}
              alt={`user avatar for ${serviceName}`}
            />

            <Heading size="large" align="center">
              &apos;{channelName}&apos; is already connected
            </Heading>
          </Flex>
          <Text color="subtle" align="center">
            If you meant to connect another account, please make sure you are
            logged into the right account on <strong>{serviceName}</strong> and
            try connecting your channel again.
          </Text>
        </Flex>
        <div className={styles.footer}>
          <Flex gap="sm" className={styles.footerActions}>
            <Button variant="primary" size="large" onClick={backToChannels}>
              Back To Channels
            </Button>
          </Flex>
        </div>
      </Flex>
    </Card>
  )
}
