import styled from 'styled-components'
import {
  black,
  grayDark,
  grayDarker,
  grayLight,
  grayLighter,
  white,
} from '@bufferapp/ui/style/colors'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: ${white};
  border-radius: 4px;
  gap: var(--space-sm);

  img {
    height: 40px;
    width: 40px;
  }
  @media (max-width: 768px) {
    flex-direction: row;
  }
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background: ${black};

  svg {
    fill: ${white};
  }
`

export const Section = styled.div`
  display: flex;

  h4 {
    color: ${grayDarker};
  }

  p {
    color: ${grayDark};
  }

  .channelActionButton {
    min-width: 127px;

    button {
      justify-content: center;
    }
  }
`

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
`

export const Toggle = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${grayLight};
  margin-left: 20px;

  button {
    cursor: pointer;
    background: ${white};
    border: none;
    height: 40px;
    width: 60px;
    @media (max-width: 540px) {
      width: 30px;
    }
  }
`

export const TitleTagWrapper = styled.div`
  display: flex;
  margin-bottom: 2px;
  gap: var(--space-xs);

  @media (max-width: 540px) {
    flex-direction: column;
  }
`

export const Tag = styled.div`
  font-size: 11px;
  font-weight: 500;
  padding: 2px 4px;
  background: ${grayLighter};
  border-radius: 3px;

  @media (max-width: 540px) {
    order: 2;
  }
`
