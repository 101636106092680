import React from 'react'
import PropTypes from 'prop-types'
import { BlockWrapper } from '../../../../account-components'
import { Notice, Loader, Text } from '@bufferapp/ui'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'

import CardDetails from '../../../../credit-card'
import InvoicesAndReceipts from './components/InvoicesAndReceipts'
import PlansCard from './components/PlansCard'

import { useAccount } from '../../context/Account'
import {
  getCurrentPlanFromAccount,
  shouldShowUpgradePaths,
  isPaidRevenueCatBillingGateway,
  hasPaymentDetails,
} from './utils/planHelpers'
import BillingInformation from './components/BillingInformation'
import { showPastDueState } from './utils/showPastDueState'
import {
  Link,
  LinkWrapper,
} from '../../../../centralized-billing/components/CentralizedBilling/styles'
import { HelpScoutBeacon } from '../../../../helpScoutBeacon'
import { isPaymentMethodBank } from '../../utils/isPaymentMethodBank'
import { isOnNonAgencyTieredPricing } from './utils/isOnNonAgencyTieredPricing'

// @ts-expect-error TS(7031) FIXME: Binding element 'refetch' implicitly has an 'any' ... Remove this comment to see the full error message
const OneBufferBillingPage = ({ refetch }): JSX.Element => {
  // Query from the Account app
  const account = useAccount()
  const currentPlan = getCurrentPlanFromAccount(account)
  const { currentOrganization } = account

  // Determine whether to show the past due state
  const showPaymentPastDue = showPastDueState({ currentOrganization })
  const isOnTieredPricing = isOnNonAgencyTieredPricing(account)
  const hasCardDetails =
    !!currentOrganization?.billing?.paymentDetails?.creditCard

  // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type '{ id: s... Remove this comment to see the full error message
  if (currentOrganization?.loading) {
    return <Loader />
  }

  const canEdit = currentOrganization?.canEdit
  const shouldShowBillingActions = shouldShowUpgradePaths(
    currentOrganization,
    currentPlan,
  )
  const shouldShowUpgradeCtasForMobilePayingCutomers =
    isPaidRevenueCatBillingGateway(currentOrganization, currentPlan)

  const showAddPaymentMethod =
    hasPaymentDetails(account) && shouldShowBillingActions && hasCardDetails
  const showInvoicesAndReceipts = shouldShowBillingActions
  const showTaxStatus = shouldShowBillingActions
  // @ts-expect-error TS(2339) FIXME: Property 'channels' does not exist on type '{ id: ... Remove this comment to see the full error message
  const lockedChannelsCount = currentOrganization.channels.filter(function (e) {
    return e.isLocked
  }).length

  const isPayingWithBank = isPaymentMethodBank(account)

  const connectedChannelsCount =
    currentOrganization && currentOrganization.channels
      ? account?.channels?.filter(
          (channel) =>
            channel.organizationId === account.currentOrganization.id &&
            !channel.isLocked,
        ).length
      : 0

  // Refetch the account after 3 seconds
  // See GROW-694 for context on this change
  // GROW-711 will improve the UX of this flow by adding the success
  // modal after redirect from Stripe Checkout
  React.useEffect(() => {
    const timer = setTimeout(() => {
      refetch()
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  // Display success modal if user migrated from the Migration Hub
  const migrationResult =
    new URLSearchParams(window.location.search).get('migration') || ''
  if (migrationResult === 'success') {
    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.success, {
      selectedPlan: { planName: currentPlan?.name },
      isMigrationFromMP: true,
    })
    // Remove URL param
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  return (
    <React.Fragment>
      {!canEdit && (
        <BlockWrapper>
          {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
          <Notice type="note">
            <InfoIcon className="note-icon" />
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
            <Text>
              Contact an Admin in your organization to view and edit billing
              details.
            </Text>
          </Notice>
        </BlockWrapper>
      )}

      {isOnTieredPricing && (
        <BlockWrapper>
          <Notice type="note" className={'tieredPricingNoe'}>
            <InfoIcon className="note-icon" />
            <Text type="p">
              You are on a custom Buffer pricing plan. To make changes, please
              contact support at{' '}
              <a href="mailto:hello@buffer.com">hello@buffer.com</a>.
            </Text>
          </Notice>
        </BlockWrapper>
      )}

      {isPayingWithBank && !isOnTieredPricing && (
        <BlockWrapper>
          <Notice type="note" className={'bankPaymentNote'}>
            <InfoIcon className="note-icon" />
            <Text type="p">
              To make changes to your plan, please contact support at{' '}
              <a href="mailto:hello@buffer.com">hello@buffer.com</a>.
            </Text>
          </Notice>
        </BlockWrapper>
      )}

      {canEdit && (
        <BlockWrapper>
          <PlansCard
            callback={(cta: string): void => {
              const { MODALS, actions } = window?.appshell || {}
              actions.openModal(MODALS.planSelector, {
                cta,
                upgradePathName: 'billing-upgrade',
              })
            }}
            userLimit={
              currentOrganization.billing?.subscription?.plan?.limits?.members
            }
            // @ts-expect-error TS(2339) FIXME: Property 'members' does not exist on type '{ id: s... Remove this comment to see the full error message
            membersCount={currentOrganization.members?.totalCount}
            subscription={currentOrganization.billing?.subscription}
            canEdit={currentOrganization.canEdit}
            channelsCount={connectedChannelsCount}
            channelsLimit={currentOrganization?.limits.channels}
            channelsLocked={lockedChannelsCount}
            upcomingInvoiceAmount={currentOrganization.billing?.upcomingInvoiceAmount?.toFixed(
              2,
            )}
            upcomingInvoiceAmountExcludingTax={currentOrganization.billing?.upcomingInvoice?.amountWithoutTax?.toFixed(
              2,
            )}
            upcomingInvoiceAmountTax={currentOrganization.billing?.upcomingInvoice?.amountTax?.toFixed(
              2,
            )}
            currency={currentOrganization.billing?.upcomingInvoice?.currency}
            currentPlan={currentPlan}
            hasCreditCard={
              !!currentOrganization.billing?.paymentDetails?.creditCard
            }
            shouldShowBillingActions={shouldShowBillingActions}
            shouldShowUpgradeCtasForMobilePayingCutomers={
              shouldShowUpgradeCtasForMobilePayingCutomers
            }
            balance={currentOrganization.billing?.balance}
            discount={currentOrganization.billing?.discount}
            showPaymentPastDue={showPaymentPastDue}
            isOnTieredPricing={isOnTieredPricing}
            isPayingWithBank={isPayingWithBank}
          />
        </BlockWrapper>
      )}

      <BlockWrapper>
        <LinkWrapper>
          <span>Need help?</span>
          <Link
            onClick={(): void => {
              HelpScoutBeacon.displayBeaconChatOptions()
            }}
          >
            Talk to our support team
          </Link>{' '}
        </LinkWrapper>
      </BlockWrapper>

      {canEdit && showAddPaymentMethod && (
        <BlockWrapper id="payment-method">
          <CardDetails
            cardDetails={
              currentOrganization.billing?.paymentDetails?.creditCard
            }
            showPaymentPastDue={showPaymentPastDue}
          />
        </BlockWrapper>
      )}

      {canEdit && showTaxStatus && (
        <BillingInformation
          address={currentOrganization.billing?.address}
          taxIds={currentOrganization.billing?.taxIds}
          taxExempt={currentOrganization.billing?.taxExempt}
        />
      )}

      {canEdit && showInvoicesAndReceipts && (
        <BlockWrapper>
          <InvoicesAndReceipts />
        </BlockWrapper>
      )}
    </React.Fragment>
  )
}

OneBufferBillingPage.propTypes = {
  refetch: PropTypes.func,
}

export default OneBufferBillingPage
