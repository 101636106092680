import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import MigratePlanToNewBufferButton from './MigratePlanButton'
import usePlanMigrationSummary from './usePlanMigrationSummary'
import { useAccount } from '../../../../context/Account'
import {
  capitalizeFirstLetter,
  getProductUrl,
} from '../../../../../../shared-utils'

import { Text, Loader, Notice } from '@bufferapp/ui'
import { Checkmark } from '@bufferapp/ui/Icon'
import {
  Container,
  PaymentMethodContainer,
  SummaryBox,
  SummaryTitle,
  SubText,
  PricingWrapper,
  CreditNoteWrapper,
  Seperator,
  MigrationFootNote,
} from './style'
import CardDetails from '../../../../../../credit-card'

const ConfirmAndPay = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'planName' implicitly has an 'any'... Remove this comment to see the full error message
  planName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'planInterval' implicitly has an '... Remove this comment to see the full error message
  planInterval,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedChannelQuantity' implicit... Remove this comment to see the full error message
  selectedChannelQuantity,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isHighlighted' implicitly has an ... Remove this comment to see the full error message
  isHighlighted,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDisabled' implicitly has an 'an... Remove this comment to see the full error message
  isDisabled,
  // @ts-expect-error TS(7031) FIXME: Binding element 'legacyPlanPrice' implicitly has a... Remove this comment to see the full error message
  legacyPlanPrice,
  // @ts-expect-error TS(7031) FIXME: Binding element 'keyFeature' implicitly has an 'an... Remove this comment to see the full error message
  keyFeature,
  // @ts-expect-error TS(7031) FIXME: Binding element 'openModal' implicitly has an 'any... Remove this comment to see the full error message
  openModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setShowingModal' implicitly has a... Remove this comment to see the full error message
  setShowingModal,
}) => {
  const account = useAccount()
  const { currentOrganization } = account

  const { data, loading, error } = usePlanMigrationSummary({
    plan: planName,
    interval: planInterval,
    quantity: selectedChannelQuantity,
  })

  // Credit due to subscription proration
  let creditAmount = data?.planMigrationSummaryViaHub?.creditAmount || 0
  // Stripe account balance
  const balance = currentOrganization?.billing?.balance || 0

  // A stripe account credit is a negative number, so convert it if so.
  if (balance < 0) {
    creditAmount += Math.abs(balance)
  }

  const billingUrl = getProductUrl('account')
    ? `${getProductUrl('account')}/billing`
    : 'https://account.buffer.com/billing'

  const shouldShowCreditNote = creditAmount > 0

  const capitalizedPlanName = capitalizeFirstLetter(planName)

  useEffect(() => {
    currentOrganization?.id &&
      BufferTracker.migrationCheckoutModalViewed({
        organizationId: currentOrganization.id,
        plan: planName,
        quantity: selectedChannelQuantity,
        interval: planInterval,
        legacyPlanPrice,
        cta: 'account-migrationHub-checkoutModal-confirmAndPay-1',
      })
  }, [currentOrganization])

  const cardDetails =
    // @ts-expect-error TS(2339) FIXME: Property 'billingDetails' does not exist on type '... Remove this comment to see the full error message
    currentOrganization?.billingDetails?.allStripeAccounts[0].billing?.card ||
    null

  if (cardDetails === null) {
    isDisabled = true
  }

  if (loading) {
    return (
      <Container>
        <Loader></Loader>
      </Container>
    )
  }

  return (
    <Container>
      <SummaryTitle>
        <Text type="h3">Summary</Text>
      </SummaryTitle>
      <SummaryBox>
        <SubText>
          <Text type="p" color="grayDark">
            You are updating to:
          </Text>
        </SubText>
        <Text type="h2" color="grayDarker">
          {capitalizedPlanName}
        </Text>
        <span>
          <Checkmark />
          <Text type="p" color="grayDarker">
            {selectedChannelQuantity} channels
          </Text>
        </span>
        <span>
          <Checkmark />
          <Text type="p" color="grayDarker">
            {keyFeature}
          </Text>
        </span>
        <Seperator />
        <SubText>
          <Text type="p" color="grayDark">
            Total due today:
          </Text>
        </SubText>
        <PricingWrapper>
          <Text type="h2" color="grayDarker">
            ${data?.planMigrationSummaryViaHub?.chargeAmount?.toFixed(2)}
          </Text>
        </PricingWrapper>
        {shouldShowCreditNote && (
          <CreditNoteWrapper>
            <Text type="p">
              You have a credit of <strong>${creditAmount?.toFixed(2)}</strong>{' '}
              which will be automatically applied to your upcoming invoices.
            </Text>
          </CreditNoteWrapper>
        )}
        <Seperator />
        <Text type="p" color="grayDark">
          ${data?.planMigrationSummaryViaHub?.intervalAmount} will be billed
          every {planInterval} until cancelled.
        </Text>
      </SummaryBox>
      <div>
        {cardDetails ? (
          <PaymentMethodContainer>
            <CardDetails cardDetails={cardDetails} withColor />
          </PaymentMethodContainer>
        ) : (
          // @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
          <Notice type="warning">
            <Text type="p">
              Please add your payment information on the{' '}
              <a href={billingUrl}>Billing page</a> to continue.
            </Text>
          </Notice>
        )}
      </div>

      <MigratePlanToNewBufferButton
        label="Confirm and Pay"
        // @ts-expect-error TS(2322) FIXME: Type '{ label: string; plan: any; selectedChannelQ... Remove this comment to see the full error message
        plan={planName}
        selectedChannelQuantity={selectedChannelQuantity}
        selectedInterval={planInterval}
        selectedPlan={planName}
        isHighlighted={isHighlighted}
        isDisabled={isDisabled}
        openModal={openModal}
        setShowingModal={setShowingModal}
      />
      <MigrationFootNote>
        <Text type="p" color="grayDark">
          All migrations are non-reversible
        </Text>
      </MigrationFootNote>
    </Container>
  )
}

ConfirmAndPay.propTypes = {
  planName: PropTypes.string.isRequired,
  planInterval: PropTypes.string.isRequired,
  selectedChannelQuantity: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  legacyPlanPrice: PropTypes.number.isRequired,
  keyFeature: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  setShowingModal: PropTypes.func.isRequired,
}

export default ConfirmAndPay
