import { useSplitEnabled } from '@buffer-mono/features'
import { useSelector } from 'react-redux'
import { getSignUpDateInEpochsMs, canStartTrial } from '../../shared-utils'

export default function useShouldShowTrialPrompt(): {
  shouldShowTrialPrompt?: boolean
  isReady: boolean
} {
  const account = useSelector((state) => state.account)
  const epochsMs = getSignUpDateInEpochsMs(account)

  const { isEnabled: showTrialPrompt, isReady: isSplitReady } = useSplitEnabled(
    'geid-trial-prompt',
    {
      email: account?.email,
      signUpDate: epochsMs,
    },
  )

  const isReady = isSplitReady && !account.isLoading
  const userCanStartTrial = canStartTrial(account)
  const shouldShowTrialPrompt = showTrialPrompt && userCanStartTrial

  return { shouldShowTrialPrompt, isReady }
}
