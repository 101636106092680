import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

// eslint-disable-next-line @typescript-eslint/naming-convention
const { _ENV, BUGSNAG_FRONTEND_KEY } = window

Bugsnag.start({
  apiKey: BUGSNAG_FRONTEND_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: _ENV,
  enabledReleaseStages: ['production', 'staging'],
})

const emptyWrapper = ({
  children,
}: {
  children: React.ReactNode
}): React.JSX.Element => <>{children}</>

const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React) || emptyWrapper

export default ErrorBoundary
