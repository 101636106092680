import { Link, Text } from '@bufferapp/ui'
import React from 'react'
import ReactPlayer from 'react-player'
import {
  ChannelThirdPartyService,
  type ChannelType,
} from '../../../../../../channel-connections/interfaces'
import { HC_UTM_PARAMS } from '../../../../../../shared-utils/constants'
import {
  getChannelTypeByService,
  getGuideUrl,
  getServiceName,
} from '../../utils/channels'

export const ServiceHeaderErrorMessage = (
  service: ChannelThirdPartyService,
): string => {
  return `Whoops, we couldn't find any ${getServiceName(
    service,
  )} ${getChannelTypeByService(service)} in your account`
}

// Body error messages
const SingleFlowTypeBodyError = (
  service: ChannelThirdPartyService,
  type?: ChannelType,
): JSX.Element => (
  <>
    <Text type="p">
      Looks like that channel is already connected to another Buffer
      organization.
    </Text>
    <ul>
      <li>
        For security reasons, each channel may only be connected to one
        organization at a time.
      </li>
      <li>
        Remove the channel from the other organization to make it available to
        connect.
      </li>
    </ul>
    <Text type="p">
      <Link href={getGuideUrl(service, type)}>
        Visit this Help Center article
      </Link>{' '}
      for more information on the issue.
    </Text>
  </>
)
const InstagramBodyError = (
  <>
    <Text type="p">
      Make sure you’re signing in with the Facebook account that has{' '}
      <a
        href={`https://support.buffer.com/article/565-troubleshooting-facebook-and-instagram-connections?${HC_UTM_PARAMS}#h_01HBE587FX3S5P0NSY4ME069Q3`}
      >
        Admin permissions for the Pages
      </a>{' '}
      you’re looking to connect.
    </Text>
    <Text type="p">
      For more details, check out our{' '}
      <a
        href={`https://support.buffer.com/article/568-connecting-your-instagram-business-or-creator-account-to-buffer?${HC_UTM_PARAMS}`}
      >
        Instagram connection guide
      </a>
      .
    </Text>
    <ReactPlayer
      url="https://buffer.wistia.com/medias/ck3itlz8kb"
      width="100%"
      height="310px"
    />
  </>
)
const LinkedInBodyError = (
  <>
    <Text type="p">
      Make sure you’re signing in with the LinkedIn account that has{' '}
      <Link
        newTab
        href={`https://support.buffer.com/article/560-using-linkedin-with-buffer?${HC_UTM_PARAMS}#01H84S2QQBHPC7W8Q3ZMV9X4YK`}
        fontWeight={700}
      >
        Super Admin permissions for the Pages
      </Link>{' '}
      you’re looking to connect.
    </Text>
  </>
)
const GoogleBodyError = (
  <>
    <Text type="p">
      Make sure you&apos;re signing in with a Google account with permissions
      for the profile you&apos;re looking to connect
    </Text>
    <Text type="p">
      For more details, check out our{' '}
      <Link
        newTab
        href={`https://support.buffer.com/article/557-using-google-business-profiles-with-buffer?${HC_UTM_PARAMS}`}
        fontWeight={700}
      >
        Google Business Profile connection guide
      </Link>
    </Text>
  </>
)
const YoutubeBodyError = (
  <Text type="p">
    Make sure you&apos;re signing in with a YouTube account with existing
    YouTube channels
  </Text>
)

export const NoChannelsErrorBodyCopies: { [key: string]: React.ReactNode } = {
  pinterest: SingleFlowTypeBodyError(ChannelThirdPartyService.pinterest),
  twitter: SingleFlowTypeBodyError(ChannelThirdPartyService.twitter),
  mastodon: SingleFlowTypeBodyError(ChannelThirdPartyService.mastodon),
  tiktok: SingleFlowTypeBodyError(ChannelThirdPartyService.tiktok),
  threads: SingleFlowTypeBodyError(ChannelThirdPartyService.threads),
  instagram: InstagramBodyError,
  googlebusiness: GoogleBodyError,
  youtube: YoutubeBodyError,
  linkedin: LinkedInBodyError,
}
