import React from 'react'
import PropTypes from 'prop-types'
import UiButton from '@bufferapp/ui/Button'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import {
  FormattedServiceNames,
  Services,
} from '../../../channel-list/constants'
import { ConnectChannelButton } from './ConnectChannelButton'

import * as Styles from './styles'
import { useSplitEnabled } from '@buffer-mono/features'
import type { OnboardingConfig } from '../../config/getOnboardingConfig'
import { useAccount } from '../../../web/src/context/Account'

type SelectChannelListProps = {
  onSelect: (service: string) => void
  onboardingConfig: OnboardingConfig
}
export const SelectChannelList = ({
  onSelect,
  onboardingConfig,
}: SelectChannelListProps): JSX.Element => {
  const disabledMessage = (key: string): string =>
    onboardingConfig.disabledServices[key]
  const isPublishing = onboardingConfig.key === 'publishing'

  const account = useAccount()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  const { isEnabled: hideTwitter } = useSplitEnabled(
    'hide-twitter-connection-option',
  )

  const { isEnabled: instagramRemindersPersonalProfilesEnabled } =
    useSplitEnabled('instagram-reminders-personal-profiles')

  const { isEnabled: instagramLoginEnabled } =
    useSplitEnabled('instagram-login')

  const showPersonalProfilesCopy =
    instagramRemindersPersonalProfilesEnabled && instagramLoginEnabled

  const shouldShowService = (service: string): boolean => {
    return !onboardingConfig.hiddenServices.includes(service)
  }

  return (
    <>
      <Styles.ServicesGrid product={onboardingConfig.key}>
        {shouldShowService(Services.YOUTUBE) && (
          <li>
            <ConnectChannelButton
              service={Services.YOUTUBE}
              formattedServiceName={FormattedServiceNames.YOUTUBE}
              onClick={(): void => {
                onSelect(FormattedServiceNames.YOUTUBE)
              }}
              disabledMessage={disabledMessage(Services.YOUTUBE)}
              product={onboardingConfig.key}
            >
              Channel
            </ConnectChannelButton>
          </li>
        )}
        {shouldShowService(Services.MASTODON) && (
          <li>
            <ConnectChannelButton
              service="Mastodon"
              onClick={(): void => onSelect('Mastodon')}
              disabledMessage={disabledMessage(Services.MASTODON)}
              product={onboardingConfig.key}
            >
              Profile
            </ConnectChannelButton>
          </li>
        )}
        <li>
          <ConnectChannelButton
            service="Facebook"
            onClick={(): void => onSelect('Facebook')}
            disabledMessage={disabledMessage(Services.FACEBOOK)}
            product={onboardingConfig.key}
          >
            Page or Group
          </ConnectChannelButton>
        </li>
        <li>
          <ConnectChannelButton
            service="Instagram"
            onClick={(): void => onSelect('Instagram')}
            disabledMessage={disabledMessage(Services.INSTAGRAM)}
            product={onboardingConfig.key}
          >
            {showPersonalProfilesCopy
              ? 'Business, Creator, or Personal'
              : 'Business or Creator accounts'}
          </ConnectChannelButton>
        </li>
        {shouldShowService(Services.TWITTER) && (
          <li>
            <ConnectChannelButton
              service="Twitter"
              formattedServiceName={FormattedServiceNames.TWITTER}
              onClick={(): void => onSelect('Twitter')}
              disabledMessage={
                hideTwitter ? disabledMessage(Services.TWITTER) : undefined
              }
              product={onboardingConfig.key}
            >
              Profile
            </ConnectChannelButton>
          </li>
        )}
        {shouldShowService(Services.LINKEDIN) && (
          <li>
            <ConnectChannelButton
              service="Linkedin"
              onClick={(): void => onSelect('Linkedin')}
              disabledMessage={disabledMessage(Services.LINKEDIN)}
              product={onboardingConfig.key}
            >
              Page {isPublishing && 'or Profile'}
            </ConnectChannelButton>
          </li>
        )}
        {shouldShowService(Services.STARTPAGE) && (
          <li>
            <ConnectChannelButton
              service="StartPage"
              onClick={(): void => onSelect('Start Page')}
              formattedServiceName={'Start Page'}
              disabledMessage={disabledMessage(Services.STARTPAGE)}
              product={onboardingConfig.key}
            >
              Link-in-bio with built-in status updates
            </ConnectChannelButton>
          </li>
        )}
        {shouldShowService(Services.TIKTOK) && (
          <li>
            <ConnectChannelButton
              service="TikTok"
              onClick={(): void => onSelect('TikTok')}
              disabledMessage={disabledMessage(Services.TIKTOK)}
              product={onboardingConfig.key}
            >
              Business Account
            </ConnectChannelButton>
          </li>
        )}
        {shouldShowService(Services.GOOGLE_BUSINESS_PROFILE) && (
          <li>
            <ConnectChannelButton
              service="GoogleBusinessProfile"
              formattedServiceName={'Google Business Profile'}
              onClick={(): void => {
                onSelect('Google Business Profile')
              }}
              disabledMessage={disabledMessage(
                Services.GOOGLE_BUSINESS_PROFILE,
              )}
              product={onboardingConfig.key}
            >
              Business Account
            </ConnectChannelButton>
          </li>
        )}
        {shouldShowService(Services.PINTEREST) && (
          <li>
            <ConnectChannelButton
              service="Pinterest"
              onClick={(): void => onSelect('Pinterest')}
              disabledMessage={disabledMessage(Services.PINTEREST)}
              product={onboardingConfig.key}
            >
              Profile
            </ConnectChannelButton>
          </li>
        )}
        {shouldShowService(Services.THREADS) && (
          <li>
            <ConnectChannelButton
              service="Threads"
              onClick={(): void => onSelect('Threads')}
              disabledMessage={disabledMessage(Services.THREADS)}
              product={onboardingConfig.key}
            >
              <Styles.NewTag>New</Styles.NewTag>
              Profile
            </ConnectChannelButton>
          </li>
        )}
        {shouldShowService(Services.BLUESKY) && (
          <li>
            <ConnectChannelButton
              service="Bluesky"
              onClick={(): void => onSelect('Bluesky')}
              disabledMessage={disabledMessage(Services.BLUESKY)}
              product={onboardingConfig.key}
            >
              <Styles.NewTag>New</Styles.NewTag>
              Profile
            </ConnectChannelButton>
          </li>
        )}
      </Styles.ServicesGrid>

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; className: st... Remove this comment to see the full error message */}
      <UiButton
        data-testid="skip-channel-connection-button"
        type="text"
        label="Connect later, explore first"
        className="skipButton"
        onClick={(): void => {
          BufferTracker.onboardingSkipped({
            organizationId: account?.currentOrganization?.id,
            product: 'account',
            step: 'channelConnection',
            ...commonTrackingProperties,
          })
          if (onboardingConfig.nextUrl) {
            window.location.assign(onboardingConfig.nextUrl)
          }
        }}
      />
    </>
  )
}

SelectChannelList.propTypes = {
  onboardingConfig: PropTypes.object,
  onSelect: PropTypes.func,
}
