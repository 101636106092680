import { FeaturesWrapper } from '@buffer-mono/features'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store, { history } from '../../store'
import { getEnvironment } from './utils/getEnvironment'

import { PopcornProvider } from '@buffer-mono/popcorn'
import ErrorBoundary from './ErrorBoundary'
import APIGatewayProvider from './apollo/providers/APIGatewayProvider'
import App from './pages/App'
import { env } from '~/env'

/**
 * Reload the app-shell when it us updated. Local only.
 */
if (env.VITE_NODE_ENV === 'development') {
  if (env.VITE_NAVIGATOR_URL.includes('local')) {
    // eslint-disable-next-line no-inner-declarations
    function check(was404: boolean): void {
      fetch(env.VITE_NAVIGATOR_URL).then((response) => {
        if (response.status === 404) {
          setTimeout(() => {
            check(true)
          }, 500)
        } else {
          if (was404) {
            window.location.reload()
          }
        }
      })
    }
    setInterval(() => {
      check(false)
    }, 5000)
  }
}

store.dispatch({
  type: 'APP_INIT',
})
const renderApp = (AppComponent: React.FC): void => {
  const root = document.getElementById('root')
  if (!root) {
    return
  }
  createRoot(root).render(
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <PopcornProvider theme="light">
            <APIGatewayProvider>
              <FeaturesWrapper environment={getEnvironment()}>
                <AppComponent />
              </FeaturesWrapper>
            </APIGatewayProvider>
          </PopcornProvider>
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>,
  )
}

renderApp(App)

export { default as middleware } from './middleware'
export { actionTypes, actions, default as reducer } from './reducer'
