import React from 'react'

import Link from '@bufferapp/ui/Link'

export const FAQData = [
  {
    title: 'Can I switch back if I change my mind?',
    content: (
      <React.Fragment>
        It’s not possible to convert a newer plan to an older one, it’s a
        one-way process.
      </React.Fragment>
    ),
  },
  {
    title: 'I’m happy with my current subscription. Can I keep it?',
    content: (
      <React.Fragment>
        Absolutely. Moving to the newer version of Buffer is optional.
      </React.Fragment>
    ),
  },
  {
    title: 'Can I try out the new features?',
    content: (
      <React.Fragment>
        We don’t have a trial for the new plan at the moment. You can learn more
        about all these features{' '}
        <Link
          newTab
          href="https://support.buffer.com/article/595-features-available-on-each-buffer-plan?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
        >
          here.
        </Link>
      </React.Fragment>
    ),
  },
  {
    title:
      'I’m part way through my current billing cycle, what happens if I switch now?',
    content: (
      <React.Fragment>
        When you move over, we’ll start a new subscription and cancel your
        current one. We’ll calculate the value of the unused time on your
        current plan, and deduct it from the cost of the new subscription.
      </React.Fragment>
    ),
  },
  {
    title: 'How long will the process take?',
    content: <React.Fragment>Just a minute or two!</React.Fragment>,
  },
  {
    title: 'Will I lose any work?',
    content: (
      <React.Fragment>
        Nothing will be lost - existing features stay the same and you will not
        lose any sent or scheduled posts or drafts.
      </React.Fragment>
    ),
  },
  {
    title:
      'Where can I learn more about the per-channel pricing, and what happens when I add or remove channels?',
    content: (
      <React.Fragment>
        You can learn more about our pricing{' '}
        <Link newTab href="https://buffer.com/pricing">
          here
        </Link>
        , and what happens when you add or remove channels{' '}
        <Link
          newTab
          href="https://support.buffer.com/article/539-adding-or-removing-a-channel-slot-from-buffer?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
        >
          here.
        </Link>
      </React.Fragment>
    ),
  },
  {
    title: 'What is Analytics?',
    content: (
      <React.Fragment>
        Analytics provides in-depth insights on most of your channels
        (Instagram, Facebook, Twitter, and LinkedIn Business Profiles). On your
        current plan, Analytics is a paid extra, but if you upgrade, Analytics
        is included for free on all plan types. Find out more about Analytics{' '}
        <Link newTab href="https://buffer.com/analyze">
          here.
        </Link>
      </React.Fragment>
    ),
  },
  {
    title: 'What is Engagement? ',
    content: (
      <React.Fragment>
        Engagement lets you quickly navigate and respond to comments on both
        Facebook and Instagram. You can see all of your comments as they come in
        and stay on top of important interactions. Buffer’s smart alerts,
        powered by machine learning, group comments by negative sentiment,
        questions, or comments about a purchase, so you can answer the most
        important comments first. Find out more about Engagement{' '}
        <Link newTab href="https://buffer.com/engage">
          here.
        </Link>
      </React.Fragment>
    ),
  },
  {
    title: 'Why should I migrate my account? ',
    content: (
      <React.Fragment>
        Moving over to the latest version of Buffer will mean you get access to
        the most complete version of Buffer with access to all available
        features. What’s more, you’ll have the most comprehensive product
        support, including updates and new features that legacy users will miss
        out on.{' '}
      </React.Fragment>
    ),
  },
  {
    title: 'How come my price is changing? ',
    content: (
      <React.Fragment>
        The pricing plan you’re currently on is our old pricing plan. It was
        created back in 2015 and has hardly changed since then. We created a new
        pricing plan last year where the price changes based on the number of
        channels you need. We feel this new price aptly reflects the value
        Buffer provides, plus it’s in-line with how other social media
        scheduling tools price their products.{' '}
      </React.Fragment>
    ),
  },
  {
    title: 'When do I need to upgrade?',
    content: (
      <React.Fragment>
        At the moment, there’s no time limit on legacy plans. For now you can
        stay on your legacy plan for as long as you like. But if you decide to
        stay on our legacy plan you won’t be able to access some of our newest
        features like Twitter Threads and Ideas.
      </React.Fragment>
    ),
  },
]
