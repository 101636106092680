import React from 'react'
import {
  Service,
  type Account,
} from '../../../pages/Channels/auth-flows/shared/SelectChannelList/utils'
import { getServiceName } from '../../../utils/serviceName'
import type { ChannelThirdPartyService } from '~/legacy/channel-connections/interfaces'

import styles from './SwitchAccount.module.css'
import mainStyles from './ConfirmChannelSelection.module.css'
import {
  ArrowDownUpIcon,
  ArrowLeftIcon,
  BufferIcon,
  Button,
  Card,
  ChannelIcon,
  CloseIcon,
  Flex,
  Heading,
  RefreshCwIcon,
  Text,
} from '@buffer-mono/popcorn'
import { trackChannelConnectionAborted } from '../tracking'
import capitalize from 'lodash/capitalize'
import { setApiVersion } from '../utils'

export function SwitchAccount({
  onBack,
  selectedService,
  account,
}: {
  onBack: () => void
  selectedService: ChannelThirdPartyService
  account: Account
}): JSX.Element {
  const serviceName = getServiceName(selectedService)

  return (
    <Card padding="none" className={mainStyles.base}>
      <div className={mainStyles.header}>
        <div className={mainStyles.switchAccount}>
          <Button
            variant="tertiary"
            onClick={(): void => {
              onBack()
            }}
          >
            <ArrowLeftIcon />
          </Button>
        </div>
        <div className={mainStyles.headerChannels}>
          <Flex gap="sm">
            <BufferIcon />
            <ArrowDownUpIcon className={mainStyles.channelsArrows} />
            <ChannelIcon type={selectedService} color="branded" />
          </Flex>
        </div>
        <Button
          className={mainStyles.closeButton}
          variant="tertiary"
          size="small"
          onClick={(): void => {
            trackChannelConnectionAborted({
              account,
              serviceName: selectedService,
            })
            window.location.assign('/channels/connect')
          }}
        >
          <CloseIcon />
        </Button>
      </div>
      <div className={styles.body}>
        <div className={styles.switchAccountIcon}>
          <RefreshCwIcon size="large" />
        </div>
        <Heading size="large" className={styles.heading}>
          Connect to a different account
        </Heading>
        <Text size="lg">
          Please go to {capitalize(serviceName)} and switch your account before
          clicking &quot;Connect to {capitalize(serviceName)}&quot; below.
        </Text>
      </div>
      <div className={styles.footer}>
        <Button
          size="large"
          onClick={(): void => {
            window.appshell.actions.connectChannel({
              selectedService:
                selectedService === 'instagram_login_api'
                  ? Service.instagram
                  : selectedService,
              authMethod: setApiVersion(selectedService),
              skipConfigurationModal: true,
              cta: `account-confirmChannel-switchAccount-${selectedService}-1`,
            })
          }}
        >
          Connect to {capitalize(serviceName)}
        </Button>
      </div>
    </Card>
  )
}
