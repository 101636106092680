import { daysFromToday } from '../../../utils/dates'

// Used to determine whether to display a banner that prompts the user
// to update their credit card details
// @ts-expect-error TS(7031) FIXME: Binding element 'orgDetails' implicitly has an 'an... Remove this comment to see the full error message
export const generateCreditCardBanner = ({ orgDetails, cta, canEdit }) => {
  if (!orgDetails) {
    return
  }

  // Expired Card (payment has already failed)
  if (orgDetails.delinquent) {
    return {
      text: !canEdit
        ? 'Oops! We were unable to process your payment. Please contact an organization admin to update card details.'
        : "Oops! We were unable to process your payment. Please update your card's details.",
      themeColor: 'orange',
      actionButton: {
        label: !canEdit ? 'See billing' : 'Update card',
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
        action: !canEdit ? () => (window.location = '/billing') : cta,
      },
    }
  }

  // Card expires soon or expires before next payment is tried
  const hasCardExpirationDetails =
    orgDetails.billing &&
    orgDetails.billing.card &&
    orgDetails.billing.card.expMonth &&
    orgDetails.billing.card.expYear

  if (hasCardExpirationDetails) {
    const { expMonth, expYear } = orgDetails.billing.card
    const expirationDate = new Date(expYear, expMonth)
    const daysUntilExpires = daysFromToday(expirationDate.getTime())
    const daysInExpirationWindow = 45

    // Display banner if card expires within 45 days
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    if (daysUntilExpires <= daysInExpirationWindow) {
      // We need to take into account when a customer's card could be expired
      // before their latest invoice is processed (their card is charged).
      // In this example, they won't be considered delinquent until AFTER a
      // payment is tried and fails on their expired card. Here we use
      // 'isExpired' as a soft check to let them know that their card is
      // expired even through it wasn't charged yet.
      // This will most likely apply to customers on an annual plan.

      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const isExpired = daysUntilExpires <= 0

      let bannerCopy

      if (!canEdit) {
        bannerCopy =
          'Your credit card has expired. Please contact an organization admin to update your details.'
      } else if (isExpired) {
        bannerCopy = 'Your credit card has expired. Please update your details.'
      } else {
        bannerCopy =
          'Your credit card will expire soon. Please update your details.'
      }

      return {
        text: bannerCopy,
        themeColor: 'orange',
        actionButton: {
          label: !canEdit ? 'See billing' : 'Update card',
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
          action: !canEdit ? () => (window.location = '/billing') : cta,
        },
      }
    }
  }

  return null
}
