import React from 'react'
import { Button, Text } from '@bufferapp/ui'
import { Tag } from '../../../../../../account-components'
import {
  blue,
  grayLighter,
  grayDarker,
} from '../../../../../../account-components/style/colors'
import { isFreePlan } from '../../utils/planHelpers'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import {
  ScheduledUpdateButtonWrapper,
  ScheduledUpdateContainer,
} from './styles'

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }

const openSubscriptionUpdate = (): void => {
  const { MODALS, actions } = window?.appshell || {}
  actions.openModal(MODALS.subscriptionUpdate, {
    cta: 'billing-plansCard-addRemoveChannels-1',
    upgradePathName: 'accountChannels-upgrade',
    shouldPickModalOnOrganizationState: true,
  })
}

const openTieredPricingModal = (): void => {
  const { MODALS, actions } = window?.appshell || {}
  actions.openModal(MODALS.quantityUpdateTieredPricing, {
    cta: 'billing-plansCard-addRemoveChannels-1',
    upgradePathName: 'accountChannels-upgrade',
  })
}

const openChurnSurvey = (isOnTrial: boolean): void => {
  if (window.appshell?.actions) {
    const { actions } = window.appshell || {}
    actions.openModal(window.appshell.MODALS.churnSurvey, {
      isOnTrial,
    })
  }
}

const openConfirmCancellationModal = (
  subscriptionType: 'plan' | 'trial',
): void => {
  if (window.appshell?.actions) {
    const { actions } = window.appshell || {}
    actions.openModal(window.appshell.MODALS.confirmCancellation, {
      subscriptionType,
    })
  }
}

export const generatePlanDetails = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'accountContext' implicitly has an 'any'... Remove this comment to see the full error message
  accountContext,
  // @ts-expect-error TS(7031) FIXME: Binding element 'callback' implicitly has an 'any'... Remove this comment to see the full error message
  callback,
  // @ts-expect-error TS(7031) FIXME: Binding element 'canEdit' implicitly has an 'any' ... Remove this comment to see the full error message
  canEdit,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasCreditCard' implicitly has an ... Remove this comment to see the full error message
  hasCreditCard,
  // @ts-expect-error TS(7031) FIXME: Binding element 'plan' implicitly has an 'any' typ... Remove this comment to see the full error message
  plan,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trial' implicitly has an 'any' ty... Remove this comment to see the full error message
  trial,
  // @ts-expect-error TS(7031) FIXME: Binding element 'scheduledUpdate' implicitly has an 'any' ty... Remove this comment to see the full error message
  scheduledUpdate,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setShowRemoveSubscriptionScheduleModal' implicitly has an 'any' ty... Remove this comment to see the full error message
  setShowRemoveSubscriptionScheduleModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setShowChurnSurveyModal... Remove this comment to see the full error message
  isRemoveScheduleLoading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setSubscriptionType... Remove this comment to see the full error message
  setSubscriptionType,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isOnTieredPricing... Remove this comment to see the full error message
  isOnTieredPricing,
}) => {
  const commonTrackingProperties =
    accountContext?.currentOrganization?.commonTrackingProperties || null

  let cancelCallback = (subscriptionType: string): void => {
    openChurnSurvey(trial?.isActive)
    setSubscriptionType(subscriptionType)
    BufferTracker.cancelPlanClicked({
      organizationId: accountContext?.currentOrganization?.id,
      ...commonTrackingProperties,
    })
  }
  // On trial
  if (trial?.isActive) {
    cancelCallback = (subscriptionType: string): void => {
      setSubscriptionType(subscriptionType)
      openConfirmCancellationModal('trial')
      BufferTracker.cancelPlanClicked({
        organizationId: accountContext?.currentOrganization?.id,
        ...commonTrackingProperties,
      })
    }
    const cta = 'billing-plansCard-changePlanFromTrial-1'
    return {
      name: <Text type="h3">{plan.name}</Text>,
      label: <Tag text="Trial" backgroundColor={blue} uppercase />,
      description: hasCreditCard ? (
        <Text type="p" color="grayDark">
          Your subscription starts on{' '}
          <strong>
            {
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              new Date(trial.endDate).toLocaleDateString('en-US', dateOptions)
            }
          </strong>
          , when your free trial ends.
        </Text>
      ) : (
        <Text type="p" color="grayDark">
          Your free trial ends in {trial.remainingDays} day
          {trial.remainingDays === 1 ? '' : 's'} on{' '}
          <strong>
            {
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              new Date(trial.endDate).toLocaleDateString('en-US', dateOptions)
            }
          </strong>
          .
        </Text>
      ),
      actions: (
        <React.Fragment>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; disabled: boo... Remove this comment to see the full error message */}
          <Button
            type="text"
            label="Cancel Trial"
            disabled={!canEdit}
            onClick={(): void => cancelCallback('trial')}
          ></Button>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; disabled: boo... Remove this comment to see the full error message */}
          <Button
            type="secondary"
            label="Change Plan"
            disabled={!canEdit}
            onClick={(): void => callback(cta)}
          ></Button>
        </React.Fragment>
      ),
    }
  }

  // Free plan
  if (isFreePlan(plan)) {
    const cta = 'billing-plansCard-upgrade-1'
    return {
      name: <Text type="h3">{plan.name}</Text>,
      label: null,
      description: (
        <Text type="p" color="grayDark">
          <strong>The Free plan is limited to 3 channels.</strong> Upgrade to
          the Essentials plan and connect as many channels as you’d like for
          just $5 each per month.
        </Text>
      ),
      actions: (
        // @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; label... Remove this comment to see the full error message
        <Button
          data-testid="account-billing-planscard-upgrade"
          type="primary"
          label="Upgrade"
          disabled={!canEdit}
          onClick={(): void => callback(cta)}
        ></Button>
      ),
    }
  }

  // Paid Plan
  const renewalDate = new Date(plan.renewalDate).toLocaleDateString(
    'en-US',
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    dateOptions,
  )
  const canceledDate = plan.canceledDate
    ? // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      new Date(plan.canceledDate).toLocaleDateString('en-US', dateOptions)
    : null
  const cta = 'billing-plansCard-changePlanFromPaid-1'

  const scheduledUpdateDescription = scheduledUpdate && (
    <ScheduledUpdateContainer>
      <Text type="p" color="grayDark">
        Your subscription plan change to{' '}
        <strong>{scheduledUpdate.newPlan}</strong> with{' '}
        <strong>{scheduledUpdate.newQuantity}</strong> channels will take effect
        on{' '}
        <strong>
          {new Date(scheduledUpdate.effectiveDate).toLocaleDateString(
            'en-US',
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            dateOptions,
          )}
        </strong>
        .
      </Text>
      <ScheduledUpdateButtonWrapper>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; label... Remove this comment to see the full error message */}
        <Button
          type="text"
          label="Revert to previous plan"
          disabled={!canEdit || isRemoveScheduleLoading}
          onClick={(): void => setShowRemoveSubscriptionScheduleModal(true)}
        />
      </ScheduledUpdateButtonWrapper>
    </ScheduledUpdateContainer>
  )

  const canceledAtPeriodEndDescription = (
    <Text type="p" color="grayDark">
      {canceledDate && `Your plan was changed on ${canceledDate}. `}You can
      continue using the <strong>{plan.name}</strong> features until the end of
      your billing cycle on <strong>{renewalDate}</strong>, when your Free
      subscription starts.
    </Text>
  )

  const renewalDescription = (
    <Text type="p" color="grayDark">
      Your subscription renews on <strong>{renewalDate}</strong>.
    </Text>
  )

  // By default, show the subscription renewal messaging
  let descriptionToShow = renewalDescription
  // If there is scheduled update, prioritize showing the scheduled update messaging
  if (scheduledUpdate) {
    descriptionToShow = scheduledUpdateDescription
    // If the subscription is canceled, show the canceled at period end messaging
  } else if (plan.isCanceledAtPeriodEnd) {
    descriptionToShow = canceledAtPeriodEndDescription
  }

  return {
    name: <Text type="h3">{plan.name}</Text>,
    label: (
      <Tag
        text={plan.interval === 'year' ? 'ANNUALLY' : 'MONTHLY'}
        color={grayDarker}
        backgroundColor={grayLighter}
      />
    ),
    description: descriptionToShow,
    actions: (
      <React.Fragment>
        {!plan.isCanceledAtPeriodEnd ? (
          // @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; label... Remove this comment to see the full error message
          <Button
            data-testid="account-billing-planscard-cancelplan"
            type="text"
            label="Cancel Plan"
            disabled={!canEdit}
            onClick={() => cancelCallback('plan')}
          ></Button>
        ) : null}
        {/* @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; label... Remove this comment to see the full error message */}
        <Button
          data-testid="account-billing-planscard-addremovechannels"
          type="secondary"
          label="Add/Remove Channels"
          disabled={!canEdit}
          onClick={
            isOnTieredPricing ? openTieredPricingModal : openSubscriptionUpdate
          }
        ></Button>
        {!isOnTieredPricing ? (
          // @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; label... Remove this comment to see the full error message */
          <Button
            data-testid="account-billing-planscard-changeplan"
            type="secondary"
            label="Change Plan"
            disabled={!canEdit}
            onClick={() => callback(cta)}
          ></Button>
        ) : null}
      </React.Fragment>
    ),
    renewalDate,
  }
}

export function hasMoreChannelsThanLimit(
  // @ts-expect-error TS(7006) FIXME: Parameter 'channelsLocked' implicitly has an 'any'... Remove this comment to see the full error message
  channelsLocked,
  // @ts-expect-error TS(7006) FIXME: Parameter 'channelsCount' implicitly has an 'any' ... Remove this comment to see the full error message
  channelsCount,
  // @ts-expect-error TS(7006) FIXME: Parameter 'channelsLimit' implicitly has an 'any' ... Remove this comment to see the full error message
  channelsLimit,
) {
  return channelsCount >= channelsLimit && channelsLocked >= 1
}

// @ts-expect-error TS(7006) FIXME: Parameter 'channelsLocked' implicitly has an 'any'... Remove this comment to see the full error message
export function getExtraInfoCopy(channelsLocked, channelsCount, channelsLimit) {
  const shouldShowLockedChannelInfo = hasMoreChannelsThanLimit(
    channelsLocked,
    channelsCount,
    channelsLimit,
  )

  return shouldShowLockedChannelInfo
    ? `${channelsLocked} Channel${channelsLocked !== 1 ? 's' : ''} locked`
    : null
}
