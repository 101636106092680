import { env } from '../env'

window.fbAsyncInit = function (): void {
  window.FB.init({
    appId: env.VITE_FB_APP_ID,
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v13.0',
  })
}

const fbScript = document.createElement('script')
fbScript.async = true
fbScript.defer = true
fbScript.crossOrigin = 'anonymous'
fbScript.src = 'https://connect.facebook.net/en_US/sdk.js'
document.body.append(fbScript)

export {}
