import { useSplitEnabled } from '@buffer-mono/features'
import { useSelector } from 'react-redux'
import { getSignUpDateInEpochsMs, isFreeUser } from '../../shared-utils'

export default function useShouldShowOnboardingSurvey(): {
  shouldShowOnboardingSurvey?: boolean
  isReady: boolean
} {
  const account = useSelector((state) => state.account)
  const epochsMs = getSignUpDateInEpochsMs(account)

  const { isEnabled: showOnboardingSurvey, isReady: isSplitReady } =
    useSplitEnabled('geid-onboarding-survey', {
      signUpDate: epochsMs,
      email: account.email,
    })

  const isReady = isSplitReady && !account.isLoading
  const isFree = isFreeUser(account)

  const shouldShowOnboardingSurvey = showOnboardingSurvey && isFree

  return { shouldShowOnboardingSurvey, isReady }
}
