import { env } from '../env'

const isOnboardingPage = window.location.pathname.includes('onboarding')
const isOAuth = window.location.pathname.includes('channels/authorization')
if (isOnboardingPage || isOAuth) {
  const navigator = document.getElementById('navigator')
  navigator?.setAttribute('data-hide-navigation', 'true')
  navigator?.setAttribute('data-hide-banners', 'true')
}

window.API_GATEWAY_URL = env.VITE_API_GATEWAY_URL
// used in async-data-fetch to pass as a header to track in DD custom metrics
window.xBufferClientId = env.VITE_X_BUFFER_CLIENT_ID
const appShellScript = document.createElement('script')
appShellScript.src = env.VITE_NAVIGATOR_URL
document.body.prepend(appShellScript)
