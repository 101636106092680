import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import UiButton from '@bufferapp/ui/Button'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { List, SkipButtonWrapper } from './styles'
import { useAccount } from '../../../web/src/context/Account'
import {
  getCurrentPlanFromAccount,
  isFreePlan,
} from '../../../web/src/pages/Billing/utils/planHelpers'

import { goToProductOnboarding } from '../../hooks/goToProductOnboarding'
import { getProducts } from '../../config/getProducts'
import { getOnboardingConfig } from '../../config/getOnboardingConfig'
import { OnboardingPage } from '../OnboardingPage'
import ProductButton from './ProductButton'
import type { CallHistoryMethodAction } from 'connected-react-router'
import { canStartTrial } from '../../../shared-utils'

type LogoProps = {
  image: string
  alt: string
}
const Logo = ({ image, alt }: LogoProps): JSX.Element => (
  <img width="125px" src={image} alt={alt} />
)

Logo.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
}

export const SelectProductPage = (): JSX.Element => {
  const dispatch = useDispatch()
  const account = useAccount()
  const currentPlan = getCurrentPlanFromAccount(account)
  const canActivateTrial = canStartTrial(account)
  const organizationId = account?.currentOrganization?.id

  const onboardingConfig = getOnboardingConfig('publishing')

  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  useEffect(() => {
    if (organizationId && currentPlan) {
      BufferTracker.productSelectorViewed({
        clientName: 'core',
        organizationId,
        product: 'account',
        onFreePlan: isFreePlan(currentPlan),
        ...commonTrackingProperties,
      })
    }
  }, [organizationId, currentPlan, commonTrackingProperties])

  return (
    <OnboardingPage
      title="Welcome to Buffer 👋"
      subtitle="Where would you like to start?"
    >
      <List>
        {getProducts(canActivateTrial).map((product) => (
          <li key={`product-${product.id}`}>
            <ProductButton
              productLogo={<Logo {...product.logo} />}
              product={product.name}
              productId={product.id}
              buttonType={product.buttonType}
              cta={product.cta}
              onClick={(): CallHistoryMethodAction =>
                dispatch(
                  goToProductOnboarding(
                    product.id,
                    organizationId,
                    commonTrackingProperties,
                  ),
                )
              }
            >
              {product.description}
            </ProductButton>
          </li>
        ))}
      </List>
      <SkipButtonWrapper>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; className: st... Remove this comment to see the full error message */}
        <UiButton
          data-testid="skip-product-selection-button"
          type="text"
          label="Skip selection"
          className="skipButton"
          onClick={(): void => {
            BufferTracker.onboardingSkipped({
              organizationId: account?.currentOrganization?.id,
              product: 'account',
              step: 'productSelection',
              ...commonTrackingProperties,
            })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
            window.location = onboardingConfig.nextUrl
          }}
        />
      </SkipButtonWrapper>
    </OnboardingPage>
  )
}
