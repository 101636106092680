import { useMutation } from '@apollo/client'
import { useEffect, useRef, useState } from 'react'

import { REQUEST_AVAILABLE_CHANNELS_FOR_ACCOUNT } from '../../../apollo/mutations/channels'
import { useAccount } from '../../../context/Account'
import { getRedirectUri } from '../../../utils/channelConnections'
import { checkOauthStateTokensMatch, getTokensForService } from '../utils'
import type {
  RequestAvailableChannelsForAccountArgs,
  UseRequestAvailableChannelsForAccountReturn,
} from './types'
import type { ConnectableChannelData } from '../types'
import { OAuthFlowErrorType } from '../../../pages/Channels/OAuthFlow/types'
import {
  getFollowBufferCookie,
  getMastodonServerCookie,
} from '../../../pages/Channels/utils/cookies'
import { ChannelThirdPartyService } from '../../../../../channel-connections/interfaces'
import { setInstagramMetadata } from '../../../utils/metadata'
import { getServiceName } from '../../../utils/serviceName'

export default function useRequestAvailableChannelsForAccount(args: {
  service: ChannelThirdPartyService
}): UseRequestAvailableChannelsForAccountReturn {
  const [error, setError] = useState<string | undefined>(undefined)
  const [errorType, setErrorType] = useState<OAuthFlowErrorType | undefined>(
    undefined,
  )
  const isMountedRef = useRef(true)
  const [connectableChannels, setConnectableChannels] =
    useState<Array<ConnectableChannelData> | null>(null)
  const account = useAccount()

  const [requestAvailableChannelsForServiceAccount, { loading }] = useMutation(
    REQUEST_AVAILABLE_CHANNELS_FOR_ACCOUNT,
    {
      onCompleted(data) {
        if (isMountedRef.current) {
          const { channels: returnedConnectableChannels } =
            data.channelConnectionList
          if (
            returnedConnectableChannels &&
            returnedConnectableChannels.length > 0
          ) {
            setConnectableChannels(returnedConnectableChannels)
          }

          // Handling errors
          if (
            data?.channelConnectionList?.__typename ===
            'ChannelConnectionNoChannelsError'
          ) {
            console.error(
              `[Channel Authorization] ${data?.channelConnectionList?.message}`,
            )
            setError(data?.channelConnectionList?.message)
            setErrorType(OAuthFlowErrorType.noChannelsError)
          }

          if (data?.channelConnectionList?.__typename === 'UnauthorizedError') {
            console.error(
              `[Channel Authorization] ${data?.channelConnectionList?.message}`,
            )
            setError(data?.channelConnectionList?.message)
            setErrorType(OAuthFlowErrorType.notEnoughPermissions)
          }

          if (data?.channelConnectionList?.__typename === 'UnexpectedError') {
            console.error(
              `[Channel Authorization] ${data?.channelConnectionList?.message}`,
            )
            setError(data?.channelConnectionList?.message)
            setErrorType(OAuthFlowErrorType.unexpectedError)
          }
        }
      },
      onError(error) {
        console.error(
          `[Channel Authorization] ${error?.name} ${error?.message} ${error}`,
        )
        setError(error?.message)
        setErrorType(OAuthFlowErrorType.unexpectedError)
      },
      context: {
        headers: {
          'x-buffer-domain': 'channel-authorization',
          'x-buffer-service': args.service,
        },
      },
    },
  )

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  function handleRequestAvailableChannelsForServiceAccount(
    args: RequestAvailableChannelsForAccountArgs,
  ): void {
    const serviceOauthTokens = getTokensForService(args.service)
    const orgId = account?.currentOrganization?.id
    const redirectUri = getRedirectUri(args.service)
    const followBuffer = getFollowBufferCookie()
    const mastodonServer = getMastodonServerCookie()

    if (!serviceOauthTokens?.code) {
      setError('No code found in the callback URL')
      setErrorType(OAuthFlowErrorType.noCodeFoundInCallbackUrl)
      return
    }

    // We wont call the mutation if our state tokens for oauth dont match
    // This is because something in the middle of the oauth process may have gone wrong
    const shouldRequestAvailableChannelsForServiceAccount =
      checkOauthStateTokensMatch(args.service) &&
      orgId &&
      serviceOauthTokens?.code

    if (shouldRequestAvailableChannelsForServiceAccount) {
      requestAvailableChannelsForServiceAccount({
        variables: {
          input: {
            code: serviceOauthTokens.code,
            redirectUri,
            service: getServiceName(args.service),
            organizationId: account.currentOrganization.id,
            metadata: {
              mastodonMetadata:
                args.service === ChannelThirdPartyService.mastodon
                  ? {
                      server: mastodonServer || null,
                      followBuffer: Boolean(followBuffer),
                    }
                  : undefined,
              twitterMetadata:
                args.service === ChannelThirdPartyService.twitter
                  ? {
                      codeVerifier: serviceOauthTokens.codeVerifier,
                    }
                  : undefined,
              instagramMetadata: setInstagramMetadata(args.service),
            },
          },
        },
      })
    } else {
      setError('State tokens do not match')
      setErrorType(OAuthFlowErrorType.unexpectedError)
    }
  }

  return {
    connectableChannels,
    handleRequestAvailableChannelsForServiceAccount: (
      args: RequestAvailableChannelsForAccountArgs,
    ) => handleRequestAvailableChannelsForServiceAccount(args),
    isRequestAvailableChannelsForServiceAccountLoading: loading,
    requestAvailableChannelsForAccountError: error,
    requestAvailableChannelsForAccountErrorType: errorType,
  }
}
