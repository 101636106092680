import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client/'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useChannelConnectionsDispatch } from '~/legacy/web/src/context/ChannelConnections/ChannelConnections'
import { ChannelConnectionsActions } from '~/legacy/web/src/components/ChannelConnections/ChannelConnectionsProvider/channelConnectionsReducer'
import { handleSuccessRedirect } from '~/legacy/web/src/components/ChannelConnections/utils'

import {
  ArrowDownUpIcon,
  Avatar,
  BufferIcon,
  Button,
  Card,
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  DropdownMenu,
  Flex,
  Heading,
  InfoIcon,
  InstagramIcon,
  RadioCard,
  Text,
} from '@buffer-mono/popcorn'

import { CONVERT_CUSTOM_CHANNEL } from '../../../../apollo/mutations/channels'
import { useAccount } from '~/legacy/web/src/context/Account'

import styles from './ConvertCustomChannel.module.css'
import type { ConnectableChannelData } from '~/legacy/web/src/hooks/channel-connections/types'
import { getCustomChannelIdToConvertFromParams } from '../utils'

import { ChannelType, Service } from '~/legacy/channel-connections/interfaces'

export function ConvertCustomChannel({
  availableChannelsForService,
  onSuccess,
  onFailure,
}: {
  availableChannelsForService: ConnectableChannelData[] | null
  onSuccess: (connectedChannelIds: string[], isNewConnection: boolean) => void
  onFailure: (cause: string) => void
}): JSX.Element | null {
  const [serviceId, setServiceId] = useState('')
  const stateDispatch = useChannelConnectionsDispatch()

  const account = useAccount()

  useEffect(() => {
    BufferTracker.channelConnectionProfileSelectorViewed({
      organizationId: account?.currentOrganization?.id || '',
      product: 'account',
      channel: 'instagram',
      clientName: 'core',
    })
  }, [account])

  const [convertCustomChannel, { data: customChannelData, loading }] =
    useMutation(CONVERT_CUSTOM_CHANNEL)

  if (
    !availableChannelsForService ||
    availableChannelsForService.length === 0 ||
    !account?.currentOrganization
  ) {
    return null
  }

  const { currentOrganization } = account

  // Success
  const successfulConnection =
    customChannelData?.convertCustomChannel?.channel?.id
  if (successfulConnection) {
    onSuccess([customChannelData.convertCustomChannel.channel.id], false)
    handleSuccessRedirect(
      [customChannelData.convertCustomChannel.channel.id],
      false, // not new connection
      true, // treated as a reconnect
    )
  }

  const isDisabled = (channel: ConnectableChannelData): boolean => {
    return channel.isLocked || channel.connectionAvailability !== 'available'
  }

  const getStatus = (channel: ConnectableChannelData): JSX.Element | null => {
    if (channel.isLocked) {
      return (
        <>
          <InfoIcon />
          <Text>
            <strong>Unavailable</strong>
          </Text>
        </>
      )
    }

    if (channel.connectionAvailability === 'alreadyConnected') {
      return (
        <>
          <CheckIcon />
          <Text>
            <strong>Connected</strong>
          </Text>
        </>
      )
    }

    return null
  }

  // Error
  const hasError = customChannelData?.convertCustomChannel?.message
  if (hasError) {
    onFailure(customChannelData?.convertCustomChannel?.message)
  }

  const personalProfile = account?.currentOrganization?.channels?.find(
    (channel) => channel.id === getCustomChannelIdToConvertFromParams(),
  )

  const professionalChannel = availableChannelsForService.find(
    (channel) => channel.serviceId === serviceId,
  )

  return (
    <Card padding="none" className={styles.dialog}>
      <div>
        <div className={styles.header}>
          <div className={styles.headerSpacing}></div>
          <div className={styles.headerChannels}>
            <Flex gap="sm">
              <BufferIcon />
              <ArrowDownUpIcon className={styles.channelsArrows} />
              <div className={styles.instagramIconWrapper}>
                <InstagramIcon />
              </div>
            </Flex>
          </div>
          <Button
            className={styles.closeButton}
            variant="tertiary"
            size="small"
            onClick={(): void => {
              stateDispatch({
                type: ChannelConnectionsActions.RESET_CHANNEL_CONNECTION_STATE,
              })
              window.location.assign('/channels')
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className={styles.body}>
          <Flex gap="lg" direction="column" align="center">
            <Heading size="large">
              Confirm the Account You&apos;d Like to Connect
            </Heading>

            <RadioCard.Group
              className={styles.radioGroup}
              onValueChange={(v): void => {
                setServiceId(v)
              }}
            >
              {availableChannelsForService.map((channel) => {
                return (
                  <RadioCard
                    value={channel?.serviceId}
                    className={styles.radio}
                    key={channel?.serviceId}
                    disabled={isDisabled(channel)}
                  >
                    <div className={styles.detailsGroup}>
                      <Avatar
                        src={channel.avatar}
                        alt={channel.name}
                        size="medium"
                      />
                      <div className={styles.channelDetails}>
                        <Text className={styles.channelName}>
                          <strong>{channel.name}</strong>
                        </Text>
                        <Text color="subtle">Professional</Text>
                      </div>
                    </div>
                    {isDisabled(channel) && (
                      <div className={styles.status}>{getStatus(channel)}</div>
                    )}
                  </RadioCard>
                )
              })}
            </RadioCard.Group>
          </Flex>
        </div>
      </div>
      <div className={styles.footer}>
        <DropdownMenu
          align="start"
          className={styles.helpMenu}
          trigger={
            <Button
              variant="tertiary"
              size="large"
              className={styles.helpButton}
            >
              Need Help
              <ChevronDownIcon />
            </Button>
          }
        >
          <DropdownMenu.Item>
            <a
              className={styles.helpLink}
              href="https://support.buffer.com/article/571-unavailable-channels-are-likely-connected-to-another-buffer-organization?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
              target="_blank"
              rel="noopener noreferrer"
            >
              My channel is unavailable
            </a>
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <a
              className={styles.helpLink}
              href="https://support.buffer.com/article/565-troubleshooting-instagram-connections?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
              target="_blank"
              rel="noopener noreferrer"
            >
              Troubleshooting Instagram Connections
            </a>
          </DropdownMenu.Item>
        </DropdownMenu>
        <Button
          size="large"
          disabled={!serviceId || loading}
          onClick={(): void => {
            const convertCustomChannelInput = {
              channelId: personalProfile?.id,
              serviceId: professionalChannel?.serviceId,
              organizationId: currentOrganization.id,
              service: Service.instagram,
              type: ChannelType.business,
              name: professionalChannel?.name,
              avatar: professionalChannel?.avatar,
            }

            convertCustomChannel({
              variables: {
                input: convertCustomChannelInput,
              },
            })
          }}
        >
          Finish Connection
        </Button>
      </div>
    </Card>
  )
}
