import React, { useState } from 'react'

import * as Styles from './styles'

import type { ConnectableService } from '../../../../interfaces'
import { useAccount } from '../../../../../web/src/context/Account'
import trackChannelTabFilterClicked from '../../../../../tracking/trackChannelTabFilterClicked'

export enum ChannelFilter {
  allChannels = 'allChannels',
  smallBusinesses = 'smallBusinesses',
  creators = 'creators',
  ecommerce = 'ecommerce',
}

export const NavFilters = ({
  allServices,
  setFilteredServices,
}: {
  allServices: ConnectableService[]
  setFilteredServices: (filteredServices: ConnectableService[]) => void
}): JSX.Element => {
  const [selectedFilter, setSelectedFilter] = useState(
    ChannelFilter.allChannels,
  )
  const account = useAccount()
  return (
    <Styles.Wrapper>
      <Styles.FilterOption
        type="button"
        currentSelection={selectedFilter === ChannelFilter.allChannels}
        onClick={(): void => {
          setSelectedFilter(ChannelFilter.allChannels)
          setFilteredServices(allServices)
          trackChannelTabFilterClicked(account, ChannelFilter.allChannels)
        }}
      >
        All Channels
      </Styles.FilterOption>
      <Styles.FilterOption
        type="button"
        currentSelection={selectedFilter === ChannelFilter.smallBusinesses}
        onClick={(): void => {
          setSelectedFilter(ChannelFilter.smallBusinesses)
          setFilteredServices(
            allServices?.filter((service) => {
              return (
                service.service === 'instagram' ||
                service.service === 'youtube' ||
                service.service === 'tiktok' ||
                service.service === 'twitter' ||
                service.service === 'startPage' ||
                service.service === 'googlebusiness'
              )
            }),
          )
          trackChannelTabFilterClicked(account, ChannelFilter.smallBusinesses)
        }}
      >
        For Small Businesses
      </Styles.FilterOption>
      <Styles.FilterOption
        type="button"
        currentSelection={selectedFilter === ChannelFilter.creators}
        onClick={(): void => {
          setSelectedFilter(ChannelFilter.creators)
          setFilteredServices(
            allServices?.filter((service) => {
              return (
                service.service === 'instagram' ||
                service.service === 'youtube' ||
                service.service === 'tiktok' ||
                service.service === 'startPage' ||
                service.service === 'linkedin' ||
                service.service === 'twitter' ||
                service.service === 'mastodon'
              )
            }),
          )
          trackChannelTabFilterClicked(account, ChannelFilter.creators)
        }}
      >
        For Creators
      </Styles.FilterOption>
      <Styles.FilterOption
        type="button"
        currentSelection={selectedFilter === ChannelFilter.ecommerce}
        onClick={(): void => {
          setSelectedFilter(ChannelFilter.ecommerce)
          setFilteredServices(
            allServices?.filter((service) => {
              return (
                service.service === 'facebook' ||
                service.service === 'instagram' ||
                service.service === 'pinterest' ||
                service.service === 'youtube' ||
                service.service === 'twitter'
              )
            }),
          )
          trackChannelTabFilterClicked(account, ChannelFilter.ecommerce)
        }}
      >
        For E-commerce
      </Styles.FilterOption>
    </Styles.Wrapper>
  )
}
