/** ------------------
 * Help links for specific services
 * - used in the Need Help? dropdown.
 *
 ------------------------------------ */
export type SupportHelpItem = {
  id: string
  title: string
  link?: string
  onSelect?: () => void
}

const HC_UTM_PARAMS =
  'utm_source=buffer&utm_medium=need-help-link&utm_campaign=channel-connections'

const instagram = [
  {
    id: 'troubleshooting-ig-fb',
    title: 'Troubleshooting Instagram connections',
    link: `https://support.buffer.com/article/565-troubleshooting-facebook-and-instagram-connections?${HC_UTM_PARAMS}`,
  },
  {
    id: 'check-your-account-type',
    title: 'How to check your Instagram Account type',
    link: `https://support.buffer.com/article/565-troubleshooting-facebook-and-instagram-connections#h_01HBEF0G5TA7NQ6541T7RKZA97?${HC_UTM_PARAMS}`,
  },
  {
    id: 'how-to-convert-account-type',
    title:
      'How to convert your Instagram account to a business or creator account',
    link: `https://support.buffer.com/article/565-troubleshooting-facebook-and-instagram-connections#h_01HBEF0G5TA7NQ6541T7RKZA97?${HC_UTM_PARAMS}`,
  },
]

const facebook = [
  {
    id: 'troubleshooting-ig-fb',
    title: 'Troubleshooting Facebook connections',
    link: `https://support.buffer.com/article/565-troubleshooting-facebook-and-instagram-connections?${HC_UTM_PARAMS}`,
  },
  {
    id: 'fb-page-not-listed',
    title: 'My Facebook page is not listed in the available options',
    link: `https://support.buffer.com/article/569-connecting-your-facebook-page-to-buffer#h_62cebd04-dff5-4e02-b4b5-3412539580de?${HC_UTM_PARAMS}`,
  },
  {
    id: 'how-to-connect-page-to-buffer',
    title: 'How to connect your Facebook Page to Buffer',
    link: `https://support.buffer.com/article/569-connecting-your-facebook-page-to-buffer?${HC_UTM_PARAMS}`,
  },
]

const generic = [
  {
    id: 'generic-unavailable',
    title: 'My channel is unavailable',
    link: `https://support.buffer.com/article/571-unavailable-channels-are-likely-connected-to-another-buffer-organization?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more?${HC_UTM_PARAMS}`,
  },
  {
    id: 'generic-speak-to-advocacy',
    title: 'Contact Support',
    onSelect: (): void => {
      if (window.Beacon) {
        window.Beacon('open')
      } else {
        window.open(`https://support.buffer.com?${HC_UTM_PARAMS}`, '_blank')
      }
    },
  },
]

export function getHelpLinks(serviceName: string): SupportHelpItem[] {
  switch (serviceName) {
    case 'instagram':
    case 'instagram_login_api':
      return [...instagram, ...generic]
    case 'facebook':
      return [...facebook, ...generic]

    default:
      return generic
  }
}
