import { Loader, Text } from '@bufferapp/ui'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSplitEnabled } from '@buffer-mono/features'

import { PageLayout } from '../../../../../account-components'
import { ChannelThirdPartyService } from '../../../../../channel-connections/interfaces'
import PostAuthenticationRedirect from '../../../components/ChannelConnections/PostAuthenticationRedirect/PostAuthenticationRedirect'
import { useChannelConnections } from '../../../context/ChannelConnections/ChannelConnections'
import { useAccount } from '../../../context/Account'
import ErrorMessage from './ErrorMessage'
import { LoadingWrapper } from './styles'
import { OAuthFlowErrorType } from './types'
import { getServiceName } from '../utils/channels'
import { isInstagramUserActionError } from './utils'
import { handleRedirects } from './utils/handleRedirects'
import { getProductUrl } from '~/legacy/shared-utils'

/** TODO: This has been copied from the appshell
 *  - this should be removed after the new Billing Page is fully rolled out
 * Injects a meta viewport tag into the document head if one does not already exist.
 * This allows the page to be responsive on mobile devices.
 */
export function injectMetaViewportTag(): void {
  const existingMeta = document.querySelector('meta[name="viewport"]')
  if (!existingMeta) {
    const meta = document.createElement('meta')
    meta.setAttribute('name', 'viewport')
    meta.setAttribute('content', 'width=device-width, initial-scale=1')
    document.head.appendChild(meta)
  }
}

export function removeMetaViewportTag(): void {
  const existingMeta = document.querySelector('meta[name="viewport"]')
  if (existingMeta) {
    document.head.removeChild(existingMeta)
  }
}

const OAuthConnection = (): JSX.Element => {
  const { service } = useParams<{
    service: ChannelThirdPartyService
  }>()
  const { connectionErrorType, loading, isRefreshingConnection } =
    useChannelConnections()
  const account = useAccount()

  const validService = Object.keys(ChannelThirdPartyService).includes(service)

  // With the new Instagram Login API (late 2024), users will go through a
  // simplifed flow to connect their Instagram accounts. They no longer
  // need to have it connected to a Facebook page. Users who have a personal
  // Instagram account are now required to convert their account to a
  // professional account to complete connection. If they cancel, or denies
  // permissions it triggers an error that we handle below:
  const { isEnabled: isInstagramLoginEnabled } =
    useSplitEnabled('instagram-login')

  handleRedirects({ service })

  useEffect(() => {
    injectMetaViewportTag()
    // remove when component unmounts
    // (the rest of app is not ready for responsive UI yet)
    return () => {
      removeMetaViewportTag()
    }
  }, [])

  if (
    isInstagramLoginEnabled &&
    isInstagramUserActionError({
      service,
      organizationId: account?.currentOrganization?.id,
    })
  ) {
    // Automatically trigger the Instagram personal profile flow for user
    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.instagramSelectAccountType, {
      accountType: 'personal',
      onCustomCloseAction: () => {
        window.location.href = `${getProductUrl('account')}/channels`
      },
    })

    // We still want to trigger the flow while rendering the blank page
    // in the background
    return (
      <PageLayout withSidebar={false} withAppShell={false}>
        <></>
      </PageLayout>
    )
  }

  const errorType = validService
    ? connectionErrorType
    : OAuthFlowErrorType.notAllowedService

  return (
    <PageLayout withSidebar={false} withAppShell={false}>
      <LoadingWrapper>
        {loading && (
          <>
            <Text type="h3">
              {`${
                isRefreshingConnection ? 'Refreshing' : 'Connecting'
              } ${getServiceName(service)}`}
            </Text>
            <Loader />
          </>
        )}
        {!loading && errorType ? (
          <ErrorMessage errorType={errorType} service={service} />
        ) : (
          <PostAuthenticationRedirect />
        )}
      </LoadingWrapper>
    </PageLayout>
  )
}

export default OAuthConnection
