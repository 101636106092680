import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  margin: 32px 0;
  width: 100%;
  max-width: 350px;
  background: linear-gradient(266.17deg, #f9edff 3.98%, #f1f3ff 91.49%);
`

export const CopyWrapper = styled.div`
  margin-bottom: 16px;
`

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    height: 40px;
    width: 40px;
  }
`
