import React from 'react'

import {
  BufferIcon,
  Button,
  Card,
  CloseIcon,
  CriticalIcon,
  EmptyState,
  Flex,
  Heading,
  Link,
  Text,
} from '@buffer-mono/popcorn'

import {
  NoChannelsErrorBodyCopies,
  ServiceHeaderErrorMessage,
} from './NoChannelsErrorCopies'

import type { ChannelThirdPartyService } from '../../../../../../channel-connections/interfaces'
import { OAuthFlowErrorType } from '../types'
import { HC_UTM_PARAMS } from '../../../../../../shared-utils/constants'

import { useAccount } from '~/legacy/web/src/context/Account'
import { trackChannelConnectionAborted } from '~/legacy/web/src/components/ChannelConnections/tracking'

import styles from './ErrorMessage.module.css'

const defaultBodyMessage = "We couldn't connect your account. Please try again."

const stealingSupportLink = `https://support.buffer.com/article/571-unavailable-channels-are-likely-connected-to-another-buffer-organization?${HC_UTM_PARAMS}`
const defaultSupportLink = `https://support.buffer.com/category/500-buffer-social-network-guides?${HC_UTM_PARAMS}`
const notEnoughPermissionsLink = `https://support.buffer.com/article/671-changing-user-permissions-in-your-organization#h_01EJGJ7PWG9MKCMJWHZJQ0A8FS?${HC_UTM_PARAMS}`
const serviceSupportLinks: { [key: string]: string } = {
  facebook: `https://support.buffer.com/article/555-using-facebook-with-buffer?${HC_UTM_PARAMS}`,
  instagram: `https://support.buffer.com/article/554-using-instagram-with-buffer?${HC_UTM_PARAMS}`,
  linkedin: `https://support.buffer.com/article/560-using-linkedin-with-buffer?${HC_UTM_PARAMS}`,
  twitter: `https://support.buffer.com/article/561-using-twitter-with-buffer?${HC_UTM_PARAMS}`,
  pinterest: `https://support.buffer.com/article/558-using-pinterest-with-buffer?${HC_UTM_PARAMS}`,
  googlebusiness: `https://support.buffer.com/article/557-using-google-business-profiles-with-buffer?${HC_UTM_PARAMS}`,
  tiktok: `https://support.buffer.com/article/559-using-tiktok-with-buffer?${HC_UTM_PARAMS}`,
  mastodon: `https://support.buffer.com/article/563-using-mastodon-with-buffer?${HC_UTM_PARAMS}`,
  youtube: `https://support.buffer.com/article/562-using-youtube-shorts-with-buffer?${HC_UTM_PARAMS}`,
}

const renderHeader = (
  service: ChannelThirdPartyService,
  errorType: OAuthFlowErrorType | null,
): React.ReactNode => {
  if (errorType === OAuthFlowErrorType.noChannelsError) {
    return ServiceHeaderErrorMessage(service)
  }

  if (errorType === OAuthFlowErrorType.lifetimeChannelConnectionError) {
    return "You've reached the limit of connected channels on your free plan"
  }

  if (errorType === OAuthFlowErrorType.stealingNotAllowed) {
    return 'Sorry, the channel you are trying to connect is unavailable'
  }

  if (errorType === OAuthFlowErrorType.notEnoughPermissions) {
    return "You currently don't have the permissions required to make the connection"
  }

  if (errorType === OAuthFlowErrorType.notAllowedService) {
    return `Looks like we've hit a snag. We don't support ${service} service yet.`
  }

  if (errorType === OAuthFlowErrorType.failedToGetCredentials) {
    return `We detect that you're logged into a different social account than the one you're trying to refresh`
  }

  return "Looks like we've hit a snag. Would you mind trying again?"
}

const renderBody = (
  service: ChannelThirdPartyService,
  errorType: OAuthFlowErrorType | null,
): React.ReactNode => {
  if (errorType === OAuthFlowErrorType.noChannelsError) {
    return NoChannelsErrorBodyCopies[service] || defaultBodyMessage
  }

  if (errorType === OAuthFlowErrorType.lifetimeChannelConnectionError) {
    return (
      <Text>
        You have reached the limit on the number of times you can connect a
        channel on Buffer&apos;s free plan. To add more, please consider a paid
        plan which also unlocks additional features.
      </Text>
    )
  }

  if (errorType === OAuthFlowErrorType.stealingNotAllowed) {
    return (
      <>
        <Text>
          It looks like that channel is already connected to another Buffer
          organization. For security reasons, each unique channel may only be
          connected to one organization at a time.
        </Text>
        <Text>
          Removing the channel from the other organization will make it
          available to connect here.{' '}
          <Link href={stealingSupportLink}>Learn more</Link> about resolving
          this issue in our Help Center.
        </Text>
      </>
    )
  }

  if (errorType === OAuthFlowErrorType.notEnoughPermissions) {
    return (
      <Text>
        You will need to be an Admin of the Organization in order to add the
        channel(s). Find more information on how to set up an Admin{' '}
        <Link href={notEnoughPermissionsLink}>here</Link>.
      </Text>
    )
  }

  if (errorType === OAuthFlowErrorType.failedToGetCredentials) {
    return (
      <Text>
        Please visit the social network directly in order to confirm you&apos;re
        logged into the correct account, then try refreshing the channel in
        Buffer again. If the problem persists, reach out to us at{' '}
        <a href="mailto:hello@buffer.com">hello@buffer.com</a>.
      </Text>
    )
  }

  const errorLink = serviceSupportLinks[service] || defaultSupportLink
  return (
    <Text>
      Something went wrong while trying to connect your channel. Check out our{' '}
      <Link href={errorLink} external>
        troubleshooting guide
      </Link>
      , and if the problem persists, please get in touch.
    </Text>
  )
}

interface ErrorMessageProps {
  errorType?: OAuthFlowErrorType | null
  service: ChannelThirdPartyService
}

const ErrorMessage = ({
  service,
  errorType = OAuthFlowErrorType.unexpectedError,
}: ErrorMessageProps): JSX.Element => {
  const showUpgradeAction =
    errorType === OAuthFlowErrorType.lifetimeChannelConnectionError
  const showBackToChannels =
    errorType !== OAuthFlowErrorType.notAllowedService && !showUpgradeAction

  const backToChannels = (): void => {
    window.location.assign('/channels/connect')
  }

  const onUpgrade = (): void => {
    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.planSelector, {
      cta: 'channelAuthorization-paywall-upgradePlan-1',
      upgradePathName: 'channelAuthorizationPaywall-upgrade',
    })
  }

  const account = useAccount()

  return (
    <Card className={styles.base} padding="none">
      <Flex
        align="center"
        direction="column"
        justify="between"
        className={styles.wrapper}
      >
        <div className={styles.header}>
          <div className={styles.headerSpacing}></div>
          <div className={styles.headerChannels}>
            <BufferIcon />
          </div>
          <Button
            className={styles.closeButton}
            variant="tertiary"
            size="small"
            onClick={(): void => {
              trackChannelConnectionAborted({
                account,
                serviceName: service,
              })
              window.location.assign('/channels/connect')
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <Flex
          direction="column"
          align="center"
          justify="center"
          gap="sm"
          className={styles.body}
        >
          <Flex direction="column" gap="lg">
            <EmptyState className={styles.emptyStateIconWrapper}>
              <CriticalIcon size="large" color="critical" />
            </EmptyState>
            <Heading
              size="large"
              align="center"
              data-testid="errorMessage-header"
            >
              {renderHeader(service, errorType)}
            </Heading>
          </Flex>
          <Text color="subtle" align="center" data-testid="errorMessage-body">
            {renderBody(service, errorType)}
          </Text>
        </Flex>

        <div className={styles.footer}>
          {showBackToChannels || showUpgradeAction ? (
            <Flex gap="sm" className={styles.footerActions}>
              {showBackToChannels && (
                <Button variant="primary" size="large" onClick={backToChannels}>
                  Back To Channels
                </Button>
              )}
              {showUpgradeAction && (
                <Button variant="primary" size="large" onClick={onUpgrade}>
                  Upgrade Plan
                </Button>
              )}
            </Flex>
          ) : null}
        </div>
      </Flex>
    </Card>
  )
}

export default ErrorMessage
