/* eslint-disable no-unused-vars */
import { useLazyQuery, useMutation } from '@apollo/client'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'

import {
  formatPagesWithConnectionStatus,
  mapRawChannel,
  Service,
} from '../../../web/src/pages/Channels/auth-flows/shared/SelectChannelList/utils'
import {
  getChannelsCookies,
  getChannelStateCookies,
  setCookie,
} from '../../../web/src/pages/Channels/utils/cookies'

import {
  CHANNELS_AUTHORIZATION,
  CHANNELS_CONNECT_WITH_RESPONSE,
  CHANNELS_FOR_CONNECTION,
} from '../../../web/src/apollo/mutations/channels'
import { CAN_CONNECT_CHANNELS } from '../../../web/src/apollo/queries/channels'
import NoChannelsError from '../../../web/src/pages/Channels/auth-flows/shared/NoChannelsError'

import { Notification } from '@bufferapp/ui'
import {
  getAuthCodeFromUrl,
  getStateTokenFromUrl,
} from '../../../web/src/pages/Channels/utils/queryParams'
import { OnboardingPage } from '../OnboardingPage'

import { useHistory } from 'react-router-dom'
import { ChannelConnectionContext } from '../../../channel-connections/context/ChannelConnection'
import type { ChannelType } from '../../../channel-connections/interfaces'
import { getProductOnboardingRoute } from '../../../routes'
import {
  getBrowserTimezone,
  getCurrentPlanNameFromAccount,
  isAtPlanLimit,
  isTrialUser,
} from '../../../shared-utils'
import { trackConnectionAttempted } from '../../../web/src/tracking'
import { getRedirectUrlAfterChannelConnected } from '../../../web/src/utils/urls'
import { SelectChannelList } from './SelectChannelList'

const SERVICE_OAUTH_REDIRECTS = [
  'linkedin',
  'pinterest',
  'tiktok',
  'google business profile',
]

const delayTimeout = 2000
const START_PAGE_URL = 'https://start-page.buffer.com/'

export const SelectChannel = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'startPageRedirect' implicitly has... Remove this comment to see the full error message
  startPageRedirect,
  // @ts-expect-error TS(7031) FIXME: Binding element 'service' implicitly has an 'any' ... Remove this comment to see the full error message
  service,
  // @ts-expect-error TS(7031) FIXME: Binding element 'account' implicitly has an 'any' ... Remove this comment to see the full error message
  account,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onboardingConfig' implicitly has ... Remove this comment to see the full error message
  onboardingConfig,
  // @ts-expect-error TS(7031) FIXME: Binding element 'pagesFromProps' implicitly has an... Remove this comment to see the full error message
  pages: pagesFromProps,
  // @ts-expect-error TS(7031) FIXME: Binding element 'loggedInFromProps' implicitly has... Remove this comment to see the full error message
  loggedIn: loggedInFromProps,
}: {}): JSX.Element => {
  const [showTypeSelector, setShowTypeSelector] = useState(false)
  const [showPages, setShowPages] = useState(false)
  const [showRedirect, setShowRedirect] = useState(false)
  const [pages, setPages] = useState(pagesFromProps)
  const [loggedInOnRedirect, setLoggedInOnRedirect] =
    useState(loggedInFromProps)
  const [showMastodonModal, setShowMastodonModal] = useState(false)

  const [error, setError] = useState(null)
  const [authError, setAuthError] = useState(null)
  const [serviceName, setServiceName] = useState('')
  const [channelType, setChannelType] = useState('profile')

  const [showModal, setShowModal] = useState(false)
  const [possibleUserChannels, setPossibleUserChannels] = useState([])

  const connectionContext = useContext(ChannelConnectionContext)
  const history = useHistory()
  const {
    startRedirect,
    setStartRedirect,
    serviceToRedirect,
    setServiceToRedirect,
  } = connectionContext

  const [channelExists, { data: existingChannels }] =
    useLazyQuery(CAN_CONNECT_CHANNELS)
  const [channelsConnect] = useMutation(CHANNELS_CONNECT_WITH_RESPONSE, {
    onCompleted(data) {
      const channelIds = data.channelsConnectWithResponse.channels.map(
        // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
        (channel) => channel.id,
      )
      window.location.href = getRedirectUrlAfterChannelConnected(
        channelIds,
        onboardingConfig,
      )
    },
    onError() {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"connection_error"' is not assig... Remove this comment to see the full error message
      setError('connection_error')
    },
  })

  useEffect(() => {
    if (pagesFromProps.length > 0) {
      setPages(pagesFromProps)
    }
  }, [pagesFromProps])

  const loggedIn = loggedInFromProps || loggedInOnRedirect

  // @ts-expect-error TS(2349) FIXME: This expression is not callable.
  const formattedPages = formatPagesWithConnectionStatus({
    pages,
    data: existingChannels,
    currentOrganizationId: account.currentOrganization.id,
  })

  useEffect(() => {
    if (loggedIn && pages.length === 0) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"no_pages"' is not assignable to... Remove this comment to see the full error message
      setError('no_pages')
    } else if (loggedIn && pages.length > 0) {
      if (pages) {
        channelExists({
          variables: {
            // @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
            channels: pages.map((page) => ({
              serviceId: page.id,
              service: getService().toLowerCase(),
              organizationId: account.currentOrganization.id,
            })),
          },
        })
      }

      if (existingChannels) {
        // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
        const connectablePages = formattedPages.filter((p) => !p.connected)
        const canAutoConnect = connectablePages.length === 1
        const channelToConnect = pages
          // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
          .map((channel) => {
            // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
            if (connectablePages.map((p) => p.id).includes(channel.id)) {
              return mapRawChannel({ channel, account, service: getService() })
            }
            return null
          })
          // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
          .filter((channel) => !!channel)
        if (canAutoConnect) {
          channelsConnect({
            variables: {
              channels: channelToConnect,
            },
          })
        } else {
          setShowPages(true)
        }
      }
    }
  }, [pages, loggedIn, existingChannels])

  useEffect(() => {
    if (showRedirect) {
      setTimeout(() => {
        login(serviceName)
      }, delayTimeout)
    }
  }, [showRedirect])

  const redirectToStartPage = () => {
    window.location.replace(`${START_PAGE_URL}onboarding`)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'selectedService' implicitly has an 'any... Remove this comment to see the full error message
  function login(selectedService) {
    switch (selectedService) {
      case 'Start Page':
        setChannelType('page')
        startPageRedirect(account.currentOrganization)
        redirectToStartPage()
        break
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'selectedService' implicitly has an 'any... Remove this comment to see the full error message
  function onServiceSelect(selectedService) {
    setError(null)
    setCookie('Onboarding', 'true')
    setServiceName(selectedService)
    login(selectedService)
  }

  function getService() {
    const [, oauthService] = window.location.search.match(/service=(\w+)/) || [] // eslint-disable-line no-unused-vars
    return oauthService || service
  }

  const { oauthChannelsType } = getChannelsCookies()
  useEffect(() => {
    if (window.location.search.match('connected=false')) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"connection_error"' is not assig... Remove this comment to see the full error message
      setError('connection_error')
    } else if (window.location.search.match('oauth=true')) {
      const [, oauthService] =
        window.location.search.match(/service=(\w+)/) || [] // eslint-disable-line no-unused-vars
      const [__, code] = window.location.search.match(/code=(.+?)(?=$|&)/) || [] // eslint-disable-line no-unused-vars

      axios
        .get(`/oauth/${oauthService}/getChannelsList`, {
          params: {
            code,
            type: oauthChannelsType,
          },
        })
        .then(({ data }) => {
          setPages(data.channels)
          setLoggedInOnRedirect(true)
        })
        .catch(() => {
          // @ts-expect-error TS(2345) FIXME: Argument of type '"no_pages"' is not assignable to... Remove this comment to see the full error message
          setError('no_pages')
        })
    }
  }, [])

  const isOnboardingSurveyPreviousPage = (): boolean => {
    if (
      history?.location?.state &&
      (history.location.state as { from: string }).from
    ) {
      return (
        (history.location.state as { from: string }).from ===
        getProductOnboardingRoute('survey')
      )
    }
    return false
  }

  const isOnTrial = isTrialUser(account)

  const title = isOnboardingSurveyPreviousPage()
    ? onboardingConfig.alternativeTitle
    : isOnTrial
    ? onboardingConfig.trialTitle
    : onboardingConfig.title
  const subtitle = (
    <React.Fragment>
      {onboardingConfig.subtitle}
      <br />
      You can always add one later.
    </React.Fragment>
  )

  const [channelsAuthorization] = useMutation(CHANNELS_AUTHORIZATION, {
    onCompleted: (data) => {
      const channelId = data?.channelsAuthorization?.channel.id
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
      window.location = getRedirectUrlAfterChannelConnected(
        [channelId],
        onboardingConfig,
      )
    },
    onError: () => {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"There was an error connecting t... Remove this comment to see the full error message
      setAuthError('There was an error connecting the channel')
    },
  })

  useEffect(() => {
    /**
     * We use the state url param to keep track of tiktok in the callback here
     * because tiktok does not accept params (like ?connectingTiktok=true) in the redirect url
     **/
    if (
      window.location.href.includes('state') &&
      account?.currentOrganization?.id
    ) {
      const stateTokenFromUrl = getStateTokenFromUrl()
      const stateTokenInCookies = getChannelStateCookies(Service.tiktok)
      if (
        stateTokenFromUrl === stateTokenInCookies &&
        stateTokenFromUrl.includes('connectingTiktok')
      ) {
        channelsAuthorization({
          variables: {
            channelAuthorization: {
              code: getAuthCodeFromUrl(),
              organizationId: account.currentOrganization.id,
              redirectUri: `${window.location.protocol}//${window.location.hostname}/oauth/tiktok/callback`,
              service: Service.tiktok,
              timezone: getBrowserTimezone(),
            },
          },
        })
      }
    }
  }, [account])

  const [channelsForConnection, { loading: channelsForConnectionLoading }] =
    useMutation(CHANNELS_FOR_CONNECTION, {
      onCompleted: (data) => {
        if (data?.channelsForConnection?.connectableChannels) {
          const { connectableChannels } = data.channelsForConnection
          setPossibleUserChannels(connectableChannels)
          setShowModal(true)
        }

        if (data?.channelsForConnection?.cause) {
          // @ts-expect-error TS(2345) FIXME: Argument of type '"There was an error connecting t... Remove this comment to see the full error message
          setAuthError('There was an error connecting the channel')
        }
      },
      onError: () => {
        // @ts-expect-error TS(2345) FIXME: Argument of type '"There was an error connecting t... Remove this comment to see the full error message
        setAuthError('There was an error connecting the channel')
      },
    })

  return (
    <OnboardingPage title={title} subtitle={subtitle}>
      <SelectChannelList
        // @ts-expect-error TS(2322) FIXME: Type '{ account: any; onSelect: (selectedService: ... Remove this comment to see the full error message
        account={account}
        onSelect={onServiceSelect}
        onboardingConfig={onboardingConfig}
      />
      {error && (
        <NoChannelsError
          service={getService()}
          type={oauthChannelsType as ChannelType}
          onClose={(): void => {
            setError(null)
          }}
        />
      )}
      {authError && (
        <Notification
          text={authError}
          onClose={(): void => setAuthError(null)}
        />
      )}
    </OnboardingPage>
  )
}

SelectChannel.propTypes = {
  startPageRedirect: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired,
  onboardingConfig: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  loggedIn: PropTypes.bool.isRequired,
}
