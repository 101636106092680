import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { asyncDataFetchReducer } from '@buffer-mono/async-data-fetch'
import { reducer as notificationsReducer } from '@buffer-mono/legacy-bufferapp-notifications'
import accountReducer from '../web/src/reducer'
import { reducer as billing } from '../billing-details'
import { reducer as channelList } from '../channel-list'
import { reducer as cancelPlan } from '../cancel-plan'
import { reducer as centralizedBilling } from '../centralized-billing'
import { reducer as creditCard } from '../credit-card'

// @ts-expect-error TS(7006) FIXME: Parameter 'history' implicitly has an 'any' type.
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    asyncDataFetch: asyncDataFetchReducer,
    notifications: notificationsReducer,
    account: accountReducer,
    billing,
    channelList,
    cancelPlan,
    centralizedBilling,
    creditCard,
  })
