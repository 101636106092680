import { useSplitEnabled } from '@buffer-mono/features'
import { useSelector } from 'react-redux'
import {
  getSignUpDateInEpochsMs,
  isFreeUser,
  isTrialUser,
} from '../../shared-utils'

export default function useShouldSkipOnboarding(): {
  shouldSkipOnboarding?: boolean
  isReady: boolean
} {
  const account = useSelector((state) => state.account)
  const epochsMs = getSignUpDateInEpochsMs(account)
  const isTrialActive = isTrialUser(account)
  const isFree = isFreeUser(account)

  const {
    isEnabled: isSplitEnaledForTrialUsers,
    isReady: isSplitReadyForTrialUsers,
  } = useSplitEnabled('geid-skip-onboarding', {
    signUpDate: epochsMs,
    email: account.email,
  })

  const {
    isEnabled: isSplitEnabledForFreeUsers,
    isReady: isSplitReadyForFreeUsers,
  } = useSplitEnabled('geid-skip-onboarding-free-users', {
    email: account.email,
  })

  const isReady =
    isSplitReadyForTrialUsers && isSplitReadyForFreeUsers && !account.isLoading
  const shouldSkipOnboarding =
    (isSplitEnaledForTrialUsers && isTrialActive) ||
    (isSplitEnabledForFreeUsers && isFree)

  return { shouldSkipOnboarding, isReady }
}
