import styled from 'styled-components'
import { BlockWrapper } from '../../../../../account-components'

export const Footer = styled.section`
  width: 100%;
  text-align: left;
  margin-top: 2.5rem;
`
export const FooterLink = styled.a`
  margin-left: 0.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  & > * + * {
    margin-left: 0.5rem;
  }
`

export const WarningMessageWrapper = styled.div`
  /* for mobile */
  @media (max-width: 768px) {
    width: 100%;
  }
  width: 512px;

  margin-bottom: 16px;
`

export const BlockWrapperStyled = styled(BlockWrapper)<{
  wide?: boolean
}>`
  max-width: ${(props): string => (props.wide ? '700px' : '600px')};
  width: 100%;
`

export const BlockStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #b8b8b8;
  border-radius: 6px;

  p {
    margin-right: 1rem;
  }
`
