import React, { useEffect, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import { Text } from '@bufferapp/ui'

import { GET_ACCOUNT } from '../../../web/src/apollo/queries/account'
import { useAccount } from '../../../web/src/context/Account'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

export const ArrowBoxTitle = styled(Text)`
  margin-bottom: 16px;
  text-align: center;
`

export const ArrowBoxFooter = styled(Text)`
  text-align: center;
`

const StartTrialCTA = styled.div`
  width: 180px;
`
export const ActivateTrial = (): JSX.Element => {
  const { refetch } = useQuery(GET_ACCOUNT)
  const account = useAccount()
  const organizationId = account?.currentOrganization?.id

  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const dispatch = useDispatch()

  const handleEvents = useCallback(({ detail }: { detail: any }) => {
    /* eslint-disable-next-line no-unsafe-optional-chaining */
    const { actionKeys } = window?.appshell
    if (detail.action === actionKeys.billingUpdated) {
      refetch()
      // redirect to connect a channel for analytics
      BufferTracker.productSolutionSelected({
        clientName: 'core',
        organizationId,
        product: 'account',
        productSolutionSelected: 'analytics',
        ...commonTrackingProperties,
      })
      dispatch(push('/onboarding/analytics'))
    }
  }, [])

  useEffect(() => {
    const { ORGANIZATION_EVENT_KEY } = window?.appshell?.eventKeys || {}
    window.addEventListener(ORGANIZATION_EVENT_KEY, handleEvents)

    return function cleanup() {
      window.removeEventListener(ORGANIZATION_EVENT_KEY, handleEvents)
    }
  }, [])

  useEffect(() => {
    const onAppShellLoad = (): void => {
      const { COMPONENTS, actions } = window.appshell || {}

      if (!actions || !COMPONENTS) {
        return
      }

      actions?.renderComponent({
        containerId: 'startTrialOnboardingCta',
        componentKey: COMPONENTS.startTrialButton,
        options: {
          cta: 'account-onboarding-startTrial-1',
          ctaButton: 'account-onboarding-startTrial-1',
          buttonType: 'secondary',
          upgradePathName: 'onboarding-trial',
        },
      })
    }

    // This has been implemented to fix the Start Trial button
    // not rendering consistantly in Safari browsers
    // Check if the page has already loaded
    if (window.appshell !== undefined) {
      onAppShellLoad()
    } else {
      window.addEventListener('load', onAppShellLoad)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onAppShellLoad)
    }
  }, [])

  return (
    <>
      <StartTrialCTA id="startTrialOnboardingCta" />
      <ArrowBoxFooter type="p" color="grayDark">
        No credit card required
      </ArrowBoxFooter>
    </>
  )
}
