import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import type { ChannelFilter } from '../channel-connections/components/channel-storefront/components/NavFilters/NavFilters'
import type { Account } from '../web/src/pages/Channels/auth-flows/shared/SelectChannelList/utils'
import { getCommonTrackingPropertiesFromAccount } from './utils'

export default function trackChannelTabFilterClicked(
  account: Account,
  tabName: ChannelFilter,
): void {
  const commonTrackingProperties =
    getCommonTrackingPropertiesFromAccount(account)
  const organizationId = account?.currentOrganization?.id || ''
  BufferTracker.channelTabFilterClicked({
    organizationId,
    tabName,
    clientName: Client.PublishWeb,
    ...commonTrackingProperties,
  })
}
