import React from 'react'
import { Button, Text } from '@bufferapp/ui'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import CaretIconDown from '@bufferapp/ui/Icon/Icons/CaretDown'
import CaretIconUp from '@bufferapp/ui/Icon/Icons/CaretUp'

import type { ConnectableService } from '../../../../../../interfaces'

import * as Styles from './styles'
import { getProductUrl } from '../../../../../../../shared-utils'

export const AccordionHeader = ({
  service,
  isOpen,
  setIsOpen,
  onConnect,
}: {
  service: ConnectableService
  isOpen: boolean
  setIsOpen: (arg: boolean) => void
  onConnect: (arg: {
    service: string
    comingSoon?: boolean
    featureId?: number
    comingSoonMessage?: string
  }) => void
}): JSX.Element => {
  const servicesWithTags = [
    'youtube',
    'facebook',
    'twitter',
    'tiktok',
    'mastodon',
    'startPage',
    'pinterest',
    'bluesky',
  ]

  if (
    service.serviceStatus.label === 'coming_soon' ||
    service.serviceStatus.label === 'new'
  ) {
    servicesWithTags.push(service.service)
  }

  const isNewAuthFlowEnabled =
    service.serviceStatus.label !== 'coming_soon' &&
    service.service !== 'startPage'

  const triggerNewConnectionFlow = (): void => {
    if (window.appshell?.actions) {
      const { actions } = window.appshell || {}
      actions.connectChannel({
        selectedService: service.service,
        destinationUrl: `${getProductUrl('account')}/channels`,
        cta: `account-storefront-channelConnect-${service.service}-1`,
      })
    }
  }

  const onConnectClick = (): void => {
    isNewAuthFlowEnabled
      ? triggerNewConnectionFlow()
      : onConnect({
          service: service.title,
          comingSoon: service.serviceStatus?.label === 'coming_soon',
          featureId: service.serviceStatus?.details?.upvoteId,
          comingSoonMessage: service.serviceStatus?.details?.message,
        })
  }

  return (
    <Styles.Header>
      <Styles.Section>
        <Styles.Logo>
          <img src={service.serviceIconUrl} alt={`${service.title} logo`} />
        </Styles.Logo>
        <Styles.Heading>
          <Styles.TitleTagWrapper>
            <h4>{service.title}</h4>

            {servicesWithTags.includes(service.service) && (
              <Styles.Tag>
                {service.service === 'youtube' && '🔥 Trending'}
                {service.service === 'pinterest' && '🔥 Trending'}
                {service.service === 'facebook' && '👑 Most Popular'}
                {service.service === 'twitter' && '✨ Top Connected'}
                {service.service === 'tiktok' && '👍 Most Engaging'}
                {service.service === 'threads' &&
                  service.serviceStatus.label === 'new' &&
                  '🌟 New'}
                {service.service === 'bluesky' &&
                  service.serviceStatus.label === 'new' &&
                  '🌟 New'}
                {service.service === 'mastodon' && '🌟 New'}
                {service.service === 'startPage' && 'by Buffer'}
                {service.serviceStatus.label === 'coming_soon' &&
                  '⌛ Coming Soon'}
              </Styles.Tag>
            )}
          </Styles.TitleTagWrapper>
          <Text type="p">{service.subtitle}</Text>
        </Styles.Heading>
      </Styles.Section>

      <Styles.Section>
        {service.serviceStatus.label === 'coming_soon' && (
          /* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: any; label: string;... Remove this comment to see the full error message */
          <Button
            data-testid={`connect-button-${service.service}`}
            type="secondary"
            className="channelActionButton"
            onClick={onConnectClick}
            label="Notify me"
          />
        )}
        {service.serviceStatus.label !== 'coming_soon' && (
          /* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: any; label: string;... Remove this comment to see the full error message */
          <Button
            data-testid={`connect-button-${service.service}`}
            type="primary"
            className="channelActionButton"
            onClick={onConnectClick}
            label={service.service === 'startPage' ? 'Create' : 'Connect'}
          />
        )}
        {service.serviceStatus.label !== 'coming_soon' && (
          <Styles.Toggle isOpen={isOpen}>
            <button
              type="button"
              onClick={(): void => {
                if (!isOpen) {
                  // Accordion is closed and user expands to read more information
                  BufferTracker.channelDetailPanelViewed({
                    service: service.service,
                  })
                }

                setIsOpen(!isOpen)
              }}
            >
              {isOpen ? <CaretIconUp /> : <CaretIconDown />}
            </button>
          </Styles.Toggle>
        )}
      </Styles.Section>
    </Styles.Header>
  )
}
