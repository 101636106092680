import React, { useEffect } from 'react'

import useConnectChannels from '../../../hooks/channel-connections/useConnectChannels/useConnectChannels'
import {
  useChannelConnections,
  useChannelConnectionsDispatch,
} from '../../../context/ChannelConnections/ChannelConnections'
import useRequestAvailableChannelsForAccount from '../../../hooks/channel-connections/useRequestAvailableChannelsForAccount/useRequestAvailableChannelsForAccount'
import { ChannelConnectionsActions } from '../ChannelConnectionsProvider/channelConnectionsReducer'
import type { ChannelThirdPartyService } from '../../../../../channel-connections/interfaces'
import type { ConnectableChannelData } from '../../../hooks/channel-connections/types'
import { cleanupChannelConnectionCookies } from '../../../pages/Channels/utils/cookies'
import { OAuthFlowErrorType } from '../../../pages/Channels/OAuthFlow/types'
import { ConvertCustomChannel } from '../../../pages/Channels/OAuthFlow/ConvertCustomChannel'
import { ConfirmChannelSelection } from '../ConfirmChannelSelection/ConfirmChannelSelection'
import { SingleChannelAlreadyConnected } from '../SingleChannelAlreadyConnected/SingleChannelAlreadyConnected'
import { getCustomChannelIdToConvertFromParams } from '../../../pages/Channels/OAuthFlow/utils'

export default function ChannelsConnectionsContainer({
  selectedService,
}: {
  selectedService: ChannelThirdPartyService
}): JSX.Element {
  const stateDispatch = useChannelConnectionsDispatch()

  const { availableChannelsForService, loading: isConnectionLoading } =
    useChannelConnections()

  const {
    handleRequestAvailableChannelsForServiceAccount,
    requestAvailableChannelsForAccountErrorType,
    connectableChannels,
  } = useRequestAvailableChannelsForAccount({ service: selectedService })

  function handleOnSuccess(
    connectedChannelIds: string[],
    isNewConnection: boolean,
  ): void {
    stateDispatch({
      type: ChannelConnectionsActions.CONNECTION_SUCCESS,
      channelIds: connectedChannelIds,
      isNewConnection,
    })
  }

  function handleOnFailure(cause: string): void {
    stateDispatch({
      type: ChannelConnectionsActions.CONNECTION_FAILED,
      errorType: cause.toLowerCase().includes('lifetime')
        ? OAuthFlowErrorType.lifetimeChannelConnectionError
        : OAuthFlowErrorType.unexpectedError,
    })
  }

  const { handleRequestConnectChannels, requestConnectChannelsErrorMessage } =
    useConnectChannels({
      onSuccess: handleOnSuccess,
      onFailure: handleOnFailure,
      service: selectedService,
    })

  function handleOnSelectChannels(
    selectedChannels: ConnectableChannelData[],
  ): void {
    stateDispatch({
      type: ChannelConnectionsActions.SET_CONNECTION_LOADING,
    })
    handleRequestConnectChannels({
      selectedChannels,
      service: selectedService,
    })
  }

  useEffect(() => {
    if (requestAvailableChannelsForAccountErrorType) {
      cleanupChannelConnectionCookies(selectedService)
      stateDispatch({
        type: ChannelConnectionsActions.CONNECTION_FAILED,
        errorType: requestAvailableChannelsForAccountErrorType,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestAvailableChannelsForAccountErrorType])

  useEffect(() => {
    stateDispatch({
      type: ChannelConnectionsActions.SET_CONNECTION_LOADING,
    })
    handleRequestAvailableChannelsForServiceAccount({
      service: selectedService,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (connectableChannels) {
      stateDispatch({
        type: ChannelConnectionsActions.SET_AVAILABLE_CHANNELS,
        availbleChannels: connectableChannels,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectableChannels])

  const shouldRenderDialog =
    selectedService &&
    availableChannelsForService &&
    !isConnectionLoading &&
    !requestConnectChannelsErrorMessage

  const isConvertingChannels =
    getCustomChannelIdToConvertFromParams() &&
    selectedService === 'instagram_login_api'

  if (isConvertingChannels && shouldRenderDialog) {
    return (
      <ConvertCustomChannel
        onSuccess={handleOnSuccess}
        onFailure={handleOnFailure}
        availableChannelsForService={availableChannelsForService}
      />
    )
  }

  // For single channel flows, if the channel a user is attempting to connect
  // is already connected, we want to let them know to log out of the service
  // and retry. This issue has led to confusion for users as they don't know
  // whether they can connect more than one channel in Buffer.
  const isSingleChannelFlowWithAlreadyConnectedChannel =
    connectableChannels?.length === 1 &&
    connectableChannels[0]?.connectionAvailability === 'alreadyConnected'

  if (isSingleChannelFlowWithAlreadyConnectedChannel) {
    return (
      <SingleChannelAlreadyConnected
        avatar={connectableChannels[0].avatar}
        channelName={connectableChannels[0].name}
        service={selectedService}
      />
    )
  }

  const shouldShowNewConfirmChannelSelection =
    availableChannelsForService?.length &&
    selectedService === 'instagram_login_api' &&
    shouldRenderDialog

  if (shouldShowNewConfirmChannelSelection) {
    return (
      <ConfirmChannelSelection
        availableChannels={availableChannelsForService}
        handleSubmit={handleOnSelectChannels}
        selectedService={selectedService}
      />
    )
  }

  return (
    <>
      {shouldRenderDialog && (
        <ConfirmChannelSelection
          availableChannels={availableChannelsForService}
          handleSubmit={handleOnSelectChannels}
          selectedService={selectedService}
        />
      )}
    </>
  )
}
