import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  TRACK_ONBOARDING_SURVEY_COMPLETED,
  TRACK_ONBOARDING_SURVEY_SKIPPED,
  TRACK_ONBOARDING_SURVEY_VIEWED,
} from '../../apollo/mutations/onboarding'

import * as Styled from './styles'
import { SurveyQuestion } from './components/SurveyQuestion/SurveyQuestion'
import { getResourceUrl } from '../../../../shared-utils/getResourceUrl'
import surveyConf from './conf/OnboardingSurveyConfiguration.json'
import { getProductOnboardingRoute, onboardingPage } from '../../../../routes'
import type { SurveyQuestionType } from './types'
import { useAccount } from '../../context/Account'
import { useHistory } from 'react-router-dom'

export const OnboardingSurveyPage = (): JSX.Element => {
  const [questionsAnswered, setQuestionsAnswered] = useState<
    SurveyQuestionType[]
  >(
    surveyConf.questions.map((question) => ({
      questionId: question.id,
      answers: [],
    })),
  )
  const account = useAccount()
  const history = useHistory()
  const currentOrganization = account.currentOrganization

  /* Mutation to track the survey actions */
  const [trackSurveySkipped] = useMutation(TRACK_ONBOARDING_SURVEY_SKIPPED)
  const [trackSurveyViewed] = useMutation(TRACK_ONBOARDING_SURVEY_VIEWED)
  const [trackSurveyCompleted] = useMutation(TRACK_ONBOARDING_SURVEY_COMPLETED)

  const goToConnectChannelsPage = (): void => {
    history.push(onboardingPage.getRoute('publishing'), {
      from: getProductOnboardingRoute('survey'),
    })
  }

  const handleQuestionChange = useCallback(
    ({ questionId, answers }: SurveyQuestionType): void => {
      setQuestionsAnswered((prevQuestionsAnswered) =>
        prevQuestionsAnswered.map((question) =>
          question.questionId === questionId
            ? { ...question, answers }
            : question,
        ),
      )
    },
    [],
  )

  /* if every item in the questionsAnswered array has a
   non-empty answers array, all the questions have been answered */
  const allQuestionsAnswered = useCallback((): boolean => {
    return (
      Array.isArray(questionsAnswered) &&
      questionsAnswered.every(
        (item: SurveyQuestionType) => item.answers.length > 0,
      )
    )
  }, [questionsAnswered])

  const skipSurvey = (): void => {
    trackSurveySkipped({
      variables: {
        input: {
          organizationId: currentOrganization.id,
          surveyId: surveyConf.id,
        },
      },
    })
    goToConnectChannelsPage()
  }
  const sendCompletedSurvey = (): void => {
    trackSurveyCompleted({
      variables: {
        input: {
          organizationId: currentOrganization.id,
          surveyId: surveyConf.id,
          questions: questionsAnswered,
        },
      },
    })
    goToConnectChannelsPage()
  }

  useEffect(() => {
    trackSurveyViewed({
      variables: {
        input: {
          organizationId: currentOrganization.id,
          surveyId: surveyConf.id,
        },
      },
    })
  }, [])

  return (
    <Styled.PageWrapper>
      <Styled.LeftSide>
        <Styled.SurveyWrapper data-surveyid={surveyConf.id}>
          {/* Survey content */}
          <Styled.Heading type="h1">
            Welcome to Buffer 👋
            <br />
            Help us understand more about you.
          </Styled.Heading>
          {surveyConf.questions.map((question, index) => (
            <SurveyQuestion
              key={index}
              questionId={question.id}
              questionLabel={question.label}
              answers={question.answers}
              multiselection={question.multiselection}
              onChangeSelection={handleQuestionChange}
            />
          ))}
          <Styled.ButtonsWrapper>
            <Styled.DoneButton
              type="primary"
              onClick={sendCompletedSurvey}
              label="Done"
              disabled={!allQuestionsAnswered()}
            />
            <Styled.SkipButton type="text" onClick={skipSurvey} label="Skip" />
          </Styled.ButtonsWrapper>
        </Styled.SurveyWrapper>
      </Styled.LeftSide>
      {/* SIDEBAR - now is empty but we could add some content in future */}
      <Styled.SurveySidebar
        bgImage={getResourceUrl('onboarding-survey-bg.jpg')}
      ></Styled.SurveySidebar>
    </Styled.PageWrapper>
  )
}
